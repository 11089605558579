import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { AiOutlineEdit } from 'react-icons/ai'
import { Form, Select, Button, Tooltip } from 'antd'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import compose from '../../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { returnNested, safeArray, nwSetToString } from '../../../../../utils/tools'
import SelectWrapper from '../../../../helpers/form/selectWrapper'
import SwitchWrapper from '../../../../helpers/form/switchWrapper'

import { NWSET_TYPE, TRACK_EVENT_TYPE } from '../../../../../utils/const'
import { DetailsPanelCard } from '../../detailPanelCard'
import DashboardContext from '../../../../../v1/dashboard/context/dashboard.context'
import { impactCategoriesVar } from '../../../../../graphql/cache'
import { setSelectedDatabasesStorageItem } from '../../../../../v1/shared/util/impact'

class WorkspaceImpactInformation extends React.Component {

  static contextType = DashboardContext

  state = {
    isMethodNW: false,
    isMethodTotalEnabled: false,
    isFormEditable: false
  }

  componentDidMount() {
    const { getImpactMethodSetsSaga, impactMethodSetsList } = this.props
    !impactMethodSetsList && getImpactMethodSetsSaga()
  }

  handleSubmit = () => {
    const { updatePageItems, spaceId, setSpaceImpactSettingsSaga, trackEventSaga, selectedProduct, impactCategories } = this.props
    const { refetchWorkspace, refetchInventoryItems, clearDatabaseSearchFilters } = this.context
    this.formRef.current.validateFields().then(values => {
      const nwSet = values.impactNwSet && JSON.parse(values.impactNwSet)
      setSelectedDatabasesStorageItem([])
      setSpaceImpactSettingsSaga({
        spaceId: spaceId,
        impactMethodId: values.impactMethod,
        impactCategoryId: values.impactCategory,
        nwSetId: returnNested(nwSet, 'id'),
        nwSetType: Number(returnNested(nwSet, 'type')),
        excludeLT: values.excludeLT,
        useMethodTotal: values.useMethodTotal,
        databases: values.databases || [],
        selectedProduct,
        cb: () => {
          impactCategoriesVar(impactCategories)
          updatePageItems && updatePageItems()
          refetchWorkspace && refetchWorkspace()
          refetchInventoryItems && refetchInventoryItems()
          clearDatabaseSearchFilters && clearDatabaseSearchFilters()
          this.setState({ isFormEditable: false })
          trackEventSaga(TRACK_EVENT_TYPE.CHANGED_IMPACT_SETTINGS)
        }
      })
    })
  }

  buildOptionsList = (value, name, key) => <Select.Option value={value} key={key || value} title={name}>{name}</Select.Option>

  onChangeImpactMethodSet = selectedImpactMethodSetId => {
    const { getImpactMethodsSaga, setImpactCategoriesAction, setNwSetsAction, setReferenceDatabasesAction } = this.props
    getImpactMethodsSaga(selectedImpactMethodSetId, () => {
      setReferenceDatabasesAction(null)
      setImpactCategoriesAction(null)
      setNwSetsAction(null)
      this.formRef.current.setFieldsValue({
        impactMethod: undefined,
        impactCategory: undefined,
        impactNwSet: undefined,
        useMethodTotal: false,
        databases: undefined
      }, () => {
        this.setState({
          isMethodNW: false,
          isMethodTotalEnabled: false
        })
      }
      )
    })
  }

  onChangeImpactMethod = selectedImpactMethodId => {
    const { getImpactMethodListSaga } = this.props
    getImpactMethodListSaga(selectedImpactMethodId, ({ referenceDatabases }) => {
      const selectedDatabases = this.formRef.current.getFieldValue('databases')
      const databases = referenceDatabases
        .filter(referenceDatabase => selectedDatabases.includes(referenceDatabase.id))
        .map(referenceDatabase => referenceDatabase.id)
      this.formRef.current.setFieldsValue({
        impactNwSet: NWSET_TYPE.NONE,
        useMethodTotal: false,
        impactCategory: undefined,
        databases
      }, this.setState({
        isMethodNW: false,
        isMethodTotalEnabled: false
      }))
    })
  }

  onChangeImpactNwSet = value => {
    const { nwSets } = this.props
    const { isMethodTotalEnabled: currentIsMethodTotalEnabled } = this.state
    const nwSet = value && JSON.parse(value)
    const isWeigthed = nwSet && returnNested(nwSet, 'type') !== NWSET_TYPE.NORMALIZED
    const isMethodNW =  isWeigthed && safeArray(nwSets).some(method => method.id === returnNested(nwSet, 'id'))
    const isMethodTotalEnabled = isMethodNW ? currentIsMethodTotalEnabled : false

    if (!isMethodNW) this.formRef.current.setFieldsValue({ useMethodTotal: false })

    this.setState({ isMethodNW, isMethodTotalEnabled })

  }

  onChangeDatabase = databases => {
    this.formRef.current.setFieldsValue({ databases })
  }

  initImpactFields() {
    const { selectedSpace } = this.props

    this.setState({
      isMethodNW: returnNested(selectedSpace, 'nwSet', 'type') ? true : false,
      isMethodTotalEnabled: returnNested(selectedSpace, 'useMethodTotal'),
    })

    this.initFormFields()
  }

  setIsMethodNW = nwSet => {
    const isWeigthed = nwSet && returnNested(nwSet, 'type') !== NWSET_TYPE.NORMALIZED
    return isWeigthed && safeArray(this.state.impactNwSetList).some(method => method.id === returnNested(nwSet, 'id'))
  }

  resetFormFields = () => {
    this.formRef.current.resetFields()
    this.setState({ isFormEditable: false })
  }

  onChangeHandler = () => {
    const { getImpactListsSaga, selectedSpace } = this.props
    const { impactMethod } = selectedSpace || {}
    getImpactListsSaga(impactMethod?.impactMethodSetID, impactMethod?.id, () => this.setState({ isFormEditable: true }, this.initImpactFields()))
  }

  onChangeMethodTotal = status => {
    if (status) this.formRef.current.setFieldsValue({ impactCategory: undefined })
    this.setState({ isMethodTotalEnabled: status })
  }

  initFormFields = () => {
    const { selectedSpace } = this.props
    const { impactMethod, nwSet, impactCategory, useMethodTotal, excludeLT, databases = [] } = selectedSpace || {}
    const formInitalValues = {
      impactMethodSets: impactMethod?.impactMethodSetID,
      impactMethod: impactMethod?.id,
      impactNwSet: nwSetToString(nwSet),
      impactCategory: impactCategory?.id,
      databases: databases.map(database => database.id),
      useMethodTotal,
      excludeLT,
    }
    this.formRef.current.setFieldsValue(formInitalValues)
  }

  formRef = React.createRef()
  render() {
    const {
      t,
      impactMethodSetsList,
      selectedSpace,
      isLoadingSpaceImpactMethods,
      isLoadingSpaceImpactSettings,
      impactMethods,
      impactCategories,
      nwSets,
      referenceDatabases
    } = this.props

    const {
      isMethodNW,
      isMethodTotalEnabled,
      isFormEditable
    } = this.state
    const { impactMethod = {}, databases = [] } = selectedSpace || {}
    const defaultDatabasesDisplayTxt = databases.map(database => database.name).join(', ')
    const selectedImpactMethodSet = impactMethodSetsList?.find(set => set.id === impactMethod.impactMethodSetID)

    const impactMethodSetsOptions = impactMethodSetsList?.map(methodSet => this.buildOptionsList(methodSet.id, methodSet.name))
    const impactMethodListOptions = impactMethods?.filter(method => method.name !== 'EN 15804+A2 Method (NMD 3.5)') // TODO MOB-4126: temporary solution until BE will fix it
      .map(method => this.buildOptionsList(method.id, method.name))

    const impactCategoryListOptions = impactCategories?.map(category => this.buildOptionsList(category.id, category.name))
    const impactNSetListOptions = nwSets?.map(method => method.type === NWSET_TYPE.NORMALIZED && this.buildOptionsList(nwSetToString(method), method.name))
    const impactWSetListOptions = nwSets?.map(method => method.type === NWSET_TYPE.WEIGHTED && this.buildOptionsList(nwSetToString(method), method.name))
    const impactNWSetListOptions = nwSets?.map(method => method.type === NWSET_TYPE.NORM_WEIGHT && this.buildOptionsList(nwSetToString(method), method.name))
    const referenceDatabaseListOptions = referenceDatabases?.map(referenceDatabase => this.buildOptionsList(referenceDatabase.id, referenceDatabase.name))
    const impactNoneSetListOptions = [ this.buildOptionsList(NWSET_TYPE.NONE, t('global.none'), t('global.none')) ]

    return (
      <DetailsPanelCard
        title={t('model.workspace_impact_settings')}
        className="workspace-general-information"
        action={!isFormEditable ?
          <Tooltip title={t('global.change')}>
            <Button
              data-cy="button-edit-impact-settings"
              className="button"
              htmlType="button"
              onClick={this.onChangeHandler}
              disabled={isLoadingSpaceImpactMethods}>
              <AiOutlineEdit size={20} />
            </Button>
          </Tooltip>
          : null
        }>
        <Form
          ref={this.formRef}
          onFinish={ this.handleSubmit }
          layout="vertical"
          hideRequiredMark
          className={classnames('field-container', { 'editable-field': isFormEditable })}
        >
          <div className='select-wrapper'>
            <SelectWrapper
              isFieldEditable={isFormEditable}
              dataCy= {'impact-method-set'}
              displayedText={returnNested(selectedImpactMethodSet, 'name')}
              fieldLabel= {t('model.impact_method_sets')}
              fieldName= "impactMethodSets"
              fieldRules= {[
                { required: true, message: t('validation.is_required', { name: 'Impact Method Set' }) }
              ]}
              dropdownMatchSelectWidth={false}
              onSelect={this.onChangeImpactMethodSet}
              placeholder={t('model.select_impact_method')}
            >
              {impactMethodSetsOptions}
            </SelectWrapper>
          </div>

          <div className='select-wrapper'>
            <SelectWrapper
              isFieldEditable={isFormEditable}
              dataCy={'impact-methods'}
              displayedText={returnNested(selectedSpace, 'impactMethod', 'name')}
              fieldLabel= {t('model.impact_method_label')}
              fieldName= "impactMethod"
              fieldRules= {[
                { required: true, message: t('validation.is_required', { name: t('model.impact_method_label') }) }
              ]}
              dropdownMatchSelectWidth={false}
              onSelect={this.onChangeImpactMethod}
              placeholder={t('model.select_impact_method')}
            >
              {impactMethodListOptions}
            </SelectWrapper>
          </div>

          <div className='select-wrapper'>
            <SelectWrapper
              mode="multiple"
              dataCy="selected-databases"
              fieldLabel={t('model.impact_databases_label')}
              fieldName="databases"
              displayedText={defaultDatabasesDisplayTxt}
              isFieldEditable={isFormEditable}
              dropdownMatchSelectWidth={false}
              onChange={this.onChangeDatabase}
              placeholder={t('model.select_database')}
            >
              {referenceDatabaseListOptions}
            </SelectWrapper>
          </div>

          <div className='select-wrapper'>
            <SelectWrapper
              isFieldEditable={isFormEditable}
              dataCy= {'impact-normalization'}
              displayedText={returnNested(selectedSpace, 'nwSet', 'name')}
              fieldLabel= {t('model.impact_normalization')}
              fieldName= "impactNwSet"
              fieldRules= {[
                { required: isMethodTotalEnabled, message: t('validation.is_required', { name: 'Impact N&W' }) }
              ]}
              dropdownMatchSelectWidth={false}
              onSelect={this.onChangeImpactNwSet}
              placeholder={t('model.select_impact_normalization')}
            >
              <Select.OptGroup label={t('global.none')}>
                {impactNoneSetListOptions}
              </Select.OptGroup>
              <Select.OptGroup label={t('model.normalized')}>
                {impactNSetListOptions}
              </Select.OptGroup>
              <Select.OptGroup label={t('model.weighted')}>
                {impactWSetListOptions}
              </Select.OptGroup>
              <Select.OptGroup label={t('model.normalized_weighted')}>
                {impactNWSetListOptions}
              </Select.OptGroup>
            </SelectWrapper>
          </div>

          <div className='select-wrapper'>
            <SelectWrapper
              isFieldEditable={isFormEditable}
              dataCy= {'impact-category'}
              displayedText={returnNested(selectedSpace, 'impactCategory', 'name')}
              fieldLabel= {t('model.impact_category_label')}
              fieldName= "impactCategory"
              fieldRules= {[
                { required: !isMethodTotalEnabled, message: t('validation.is_required', { name: 'Impact category' }) }
              ]}
              dropdownMatchSelectWidth={false}
              disabled={isMethodTotalEnabled}
              placeholder={t('model.select_impact_category')}
            >
              {impactCategoryListOptions}
            </SelectWrapper>
          </div>

          <SwitchWrapper
            isFieldEditable={isFormEditable}
            dataCy= {'normalization-totals'}
            switchStatus={returnNested(selectedSpace, 'useMethodTotal')}
            fieldLabel= {t('model.single_score')}
            fieldName= "useMethodTotal"
            disabled={!isMethodNW}
            onChange={this.onChangeMethodTotal}
          />

          <SwitchWrapper
            isFieldEditable={isFormEditable}
            dataCy= {'exclude-lt'}
            switchStatus={returnNested(selectedSpace, 'excludeLT')}
            fieldLabel= {t('model.exclude_lt_impacts')}
            fieldName= "excludeLT"
            placeholder={t('model.select_impact_category')}
          />
          {isFormEditable ?
            <Form.Item>
              <React.Fragment>
                <Button
                  data-cy="button-save-impact-settings"
                  type="button"
                  htmlType="submit"
                  disabled={isLoadingSpaceImpactSettings}
                  className="button button-right-margin"
                  icon={<CheckOutlined />}>
                  {t('global.save')}
                </Button>
                <Button data-cy="button-edit-impact-settings" className="button" type="button" onClick={this.resetFormFields} ><CloseOutlined /> {t('global.cancel')}</Button>
              </React.Fragment>

            </Form.Item>
            : null
          }
        </Form>
      </DetailsPanelCard>
    )
  }
}

WorkspaceImpactInformation.propTypes = {
  selectedSpace: PropTypes.object,
  t: PropTypes.func
}
export { WorkspaceImpactInformation }
export default compose(
  withTranslation()
)(WorkspaceImpactInformation)
