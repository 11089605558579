export const CATEGORY_TITLES_MAP: {[key: string]: string} = {
  c0: 'Dataset type',
  c1: 'Sector',
  c2: 'Energy',
  c3: 'Other sectors',
  c4: 'Waste type',
  c5: 'Transport system',
  c6: 'Waste treatment',
  c7: 'Transport mode',
  c8: 'Chemical',
  c9: 'Electricity source',
  c10: 'Electricity mix',
  c11: 'Fuel\'s life cycle',
  c12: 'Agri & food products',
  c13: 'Capital goods',
  c14: 'Metals',
  c15: 'Heat source',
  c16: 'Construction materials',
  c17: 'Textile processing',
  c18: 'Electronics',
  c19: 'Voltage',
  c20: 'Textile life cycle stages',
  c21: 'Recycling rate',
  c22: 'Fuels',
  c23: 'Buildings & infrastructure',
  c24: 'Machinery and equipment',
  c25: 'Vehicles',
  c26: 'Fertiliser',
};

export const ELEMENTARY_FLOW_ROOT:string = 'Elementary flows'
