import React, { useState } from 'react'

import { TImpactDatasetProvider, TPageData } from '../interface/impact-dataset.context'
import { ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import ImpactDatasetContext from './context/impact-dataset.context'

type TImpactDatasetProviderProps = {
  children?: React.ReactNode
}

export const ImpactDatasetProvider = ({
  children
}: TImpactDatasetProviderProps) => {
  const [ impactDataset, setImpactDataset ] = useState<TImpactDatasetProvider>({
    selectedDatasetViewType: ImpactDatasetViewType.DatabaseSearch,
    referenceProducts: [],
    makeInitDatasetsCall: true,
    initResultsAreLoading: true,
  })

  const updateImpactDataset = (newState: TImpactDatasetProvider) => {
    setImpactDataset(prevState => ({ ...prevState, ...newState }))
  }

  const updateFilterValue = (variables: { [key: string]: any }, currentPage: number = 1) => {
    const { selectedDatasetViewType, databaseSearchPageData, elementaryFlowPageData } = impactDataset
    const filterComponent = selectedDatasetViewType === ImpactDatasetViewType.DatabaseSearch
      ? { databaseSearchPageData: { ...databaseSearchPageData, ...variables, currentPage } }
      : { elementaryFlowPageData: { ...elementaryFlowPageData, ...variables, currentPage } }
    updateImpactDataset({ ...filterComponent })
  }

  const getDatasetPageData = (): TPageData => {
    const { selectedDatasetViewType, databaseSearchPageData = {}, elementaryFlowPageData = {} } = impactDataset
    return selectedDatasetViewType === ImpactDatasetViewType.DatabaseSearch
      ? databaseSearchPageData
      : elementaryFlowPageData
  }

  return (
    <ImpactDatasetContext.Provider value={{
      ...impactDataset,
      updateImpactDataset,
      updateFilterValue,
      getDatasetPageData
    }}
    >
      { children }
    </ImpactDatasetContext.Provider>
  )
}
