import React, { useContext } from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase } from '@fortawesome/pro-regular-svg-icons'

import { useTranslation } from 'react-i18next'
import { HeaderComponent } from '../../shared/component/general/header.component'
import { SearchDatasetLayout } from './search-dataset.layout'
import { ImpactDatasetTabsComponent } from '../component/impact-dataset-tabs.component'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { ImpactDatasetViewMode, ImpactDatasetViewType } from '../enum/impact-dataset-view-type'

import { CustomImpactContainer } from '../container/custom-impact.container'
import { DetailDatasetLayout } from './detail-dataset.layout'
import { useDatasetNavigate } from '../hook/use-dataset-navigate'
import { DatasetsCoreContainer } from '../container/datasets-core.container'
import { CompareDatasetDetailComponent } from '../component/compare-dataset-detail.component'

const ImpactDatasetWrapper = styled.div`
  background: var(--primarydark-50);
`

const TapWrapper = styled.div`
  height: 2.75rem;
  z-index:2;
`

const ContentWrapper = styled.div`
  top: 2.26rem;
  box-shadow: 0 4px 16px 0px rgba(0,0,0,0.2);
`

const DetailWrapper = styled.div`
  box-shadow: 0 4px 16px 0px rgba(0,0,0,0.2);
`

const MainSection = styled.div<{ minusTop?: string }>`
  margin-top: ${props => props.minusTop};
`

export const ImpactDatasetLayout = () => {
  const { t } = useTranslation([ 'impact-dataset', 'common' ])
  const { selectedEntity, activeTopBanners } = useContext<IDashboardContext>(DashboardContext)
  const { viewMode } = useDatasetNavigate()

  const {
    selectedDatasetViewType,
    selectedReferenceProduct,
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)

  const isSelectedViewType = (viewType: ImpactDatasetViewType) => viewType === selectedDatasetViewType
  const isSelectedViewMode = (viewModeToCheck: ImpactDatasetViewMode) => viewModeToCheck === viewMode

  const someTopBannerIsActive = !!activeTopBanners?.length

  const getHeaderLabel = () => {
    const { unit, name } = selectedEntity || {}
    if (viewMode === ImpactDatasetViewMode.Detail && selectedReferenceProduct?.id) {
      return selectedReferenceProduct.name
    }

    switch (selectedDatasetViewType) {
    case ImpactDatasetViewType.DatabaseSearch:
      return t('labels.header', { context: 'dataset', unit, name })
    case ImpactDatasetViewType.ElementaryFlow:
      return t('labels.header', { context: 'elementaryFlow', unit, name })
    case ImpactDatasetViewType.CustomImpact:
      return t('labels.header', { context: 'customImpact', unit, name })
    }
  }

  const getDisplayContent = () => {
    switch (viewMode) {
    case ImpactDatasetViewMode.Detail:
      return (
        <>
          <DetailWrapper data-testid="dataset-detail-content" className="flex flex-column w-full h-full absolute">
            <DetailDatasetLayout />
          </DetailWrapper>
        </>
      )
    case ImpactDatasetViewMode.Compare:
      return (
        <DetailWrapper data-testid="dataset-compare-datasets" className="flex flex-column w-full h-full relative">
          <CompareDatasetDetailComponent />
        </DetailWrapper>
      )
    }

    return (
      <>
        <TapWrapper data-testid="dataset-search-tabs" className="flex justify-content-end w-full absolute top-0 z-2 overflow-hidden">
          <ImpactDatasetTabsComponent />
        </TapWrapper>

        <ContentWrapper
          data-testid="dataset-search-content"
          className="flex flex-column w-full absolute"
          style={{ bottom: someTopBannerIsActive ? '3.75rem' : 0 }}
        >
          { !isSelectedViewType(ImpactDatasetViewType.CustomImpact) ? <SearchDatasetLayout /> : <CustomImpactContainer /> }
        </ContentWrapper>
      </>
    )
  }

  const contentMinusTop = isSelectedViewMode(ImpactDatasetViewMode.Result) ? '-2rem' : '-0.5rem'

  return (
    <DatasetsCoreContainer>
      <ImpactDatasetWrapper className="flex w-full h-screen flex-column px-6">
        <div data-testid="database-search-header" className="flex flex-column w-full h-8rem mb-3 py-2">
          <HeaderComponent
            defaultBackTo={t('labels.tab', { context: 'model', ns: 'common' })}
            label={getHeaderLabel() || ''}
            icon={(<FontAwesomeIcon data-testid="header-label-icon" icon={faDatabase} className="text-3xl" />)}
          />
        </div>
        <MainSection className="flex-grow-1 w-full relative" minusTop={contentMinusTop}>
          { getDisplayContent() }
        </MainSection>
      </ImpactDatasetWrapper>
    </DatasetsCoreContainer>
  )
}
