import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client'
import { PRODUCT_WITH_IMPACT } from '../../../graphql/query';
import { IDashboardContext } from '../../../shared/interface/workspace-context-type';
import DashboardContext from '../../../dashboard/context/dashboard.context';
import { IImpact, IProduct } from '../../../model';
import { ProductDetailPanelLayout } from '../../layout/product-detail-panel.layout';
import { ProgressSpinnerComponent } from '../../../shared/component/general/progress-spinner.component';

type TProductDetailPanelContainerProps = {
  productId: string,
  selectedInventoryKey?: string | null,
  afterChangeSidebarCallback?: (param?: Object) => void
}
export const ProductDetailPanelContainer = ({
  productId,
  selectedInventoryKey,
  afterChangeSidebarCallback
}: TProductDetailPanelContainerProps) => {
  const { updateDashboard } = useContext<IDashboardContext>(DashboardContext);

  const {
    loading: loadingProductWithImpact,
    data: productWithImpactData
  } = useQuery(PRODUCT_WITH_IMPACT, {
    variables: { id: productId },
    fetchPolicy: 'no-cache'
  });

  const { product = null, impact = null } = productWithImpactData?.productWithImpact || {}
  useEffect(() => {
    if (!loadingProductWithImpact && productWithImpactData) {
      updateDashboard({
        selectedInventoryKey,
        selectedEntity: product as IProduct,
        selectedEntityImpact: impact as IImpact,
        afterChangeSidebarCallback
      })
    }

    return () => {
      updateDashboard({ selectedEntity: undefined, afterChangeSidebarCallback: undefined })
    }
  }, [ loadingProductWithImpact, productWithImpactData ]);

  return (
    <>
      { loadingProductWithImpact ? <ProgressSpinnerComponent size={2} /> : <ProductDetailPanelLayout /> }
    </>
  );
}
