import React, { useContext } from 'react'
import styled from 'styled-components'

import { DetailContentComponent } from '../component/detail-content.component'
import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { IProduct } from '../../model'

interface ContentWrapperProps {
  readonly showSearchTips?: boolean
}

const SidebarWrapper = styled.div<ContentWrapperProps>`
  width: 23rem;
  box-shadow: 10px 0px 16px -12px rgba(0,0,0,0.2);
`

const ContentWrapper = styled.div<ContentWrapperProps>`
  width: calc(100% - 23rem);
`

export const DetailDatasetLayout = () => {
  const {
    selectedDatasetViewType = ImpactDatasetViewType.DatabaseSearch,
    selectedReferenceProduct
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const { selectedEntity } = useContext(DashboardContext)

  const isDatabaseSearchViewType = selectedDatasetViewType === ImpactDatasetViewType.DatabaseSearch
  const { referenceProduct: currentReferenceProduct } = selectedEntity as IProduct || {}
  const referenceProduct = selectedReferenceProduct || currentReferenceProduct || {}

  return (
    <div className="flex bg-white h-full overflow-hidden">
      <SidebarWrapper data-testid="detail-dataset-sidebar" className="h-full p-4">
        <DetailContentComponent
          isSidebar
          isDatabaseSearchViewType={isDatabaseSearchViewType}
          selectedReferenceProduct={referenceProduct}
        />
      </SidebarWrapper>

      <ContentWrapper data-testid="detail-dataset-content" className="flex justify-content-center p-5">
        <DetailContentComponent
          isDatabaseSearchViewType={isDatabaseSearchViewType}
          selectedReferenceProduct={referenceProduct}
        />
      </ContentWrapper>
    </div>
  )
}
