import { createContext } from 'react'
import { TImpactDatasetContext, TPageData } from '../../interface/impact-dataset.context'

const ImpactDatasetContext = createContext<TImpactDatasetContext>({
  updateImpactDataset: () => {},
  updateFilterValue: () => {},
  getDatasetPageData: () => ({} as TPageData)
})

export default ImpactDatasetContext
