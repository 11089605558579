import React, { useContext, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { TImpactDatasetContext, TPageData } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { SEARCH_DATASETS, SEARCH_ELEMENTARY_FLOWS } from '../../graphql/query/reference'
import { ReferenceProductType } from '../enum/reference-product'
import {
  SearchType, FilterDatasetType, Facet,
  FilterElementaryFlowType,
  FilterElementaryFlowProperty
} from '../../../__generated__/graphql'
import { PAGINATION } from '../../../utils/const'
import { getFacetsLists, updateFacetsListsCounts } from '../util/facets'
import { ELEMENTARY_FLOW_ROOT } from '../constant/categories'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'

type TImpactDatasetContainerProps = {
  type: ReferenceProductType
  impactMethodId: string
  children?: React.ReactNode
}
export const ImpactDatasetContainer = ({
  type,
  impactMethodId,
  children
}: TImpactDatasetContainerProps) => {
  const {
    updateFilterValue,
    updateImpactDataset,
    makeInitDatasetsCall,
    getDatasetPageData = () => ({} as TPageData),
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const {
    query = '',
    databaseList,
    propertyList,
    locationList,
    currentPage = 1,
    locationRegionList,
    commonCategoryList,
    databaseCategoryList,
    selectedSortBy,
    selectedSortOrder,
    selectedDatabases = [],
    selectedProperties = [],
    selectedLocations = [],
    selectedFlowProperties = [],
    selectedLocationRegions = [],
    selectedCommonCategories = [],
    selectedDatabaseCategories = [],
    selectedElementaryFlowCategories = []
  } = getDatasetPageData()

  const {
    updateDashboard
  } = useContext<IDashboardContext>(DashboardContext)

  const clearDatabaseSearchFilters = () => {
    updateFilterValue({
      selectedCommonCategories: [],
      selectedDatabaseCategories: [],
      selectedElementaryFlowCategories: [],
      selectedDatabases,
      selectedLocations: [],
      selectedLocationRegions: [],
      selectedProperties: [],
      selectedFlowProperties: []
    })
    updateImpactDataset({
      makeInitDatasetsCall: true
    })
  }

  const handleFetchedData = (dataResults: any) => {
    if (makeInitDatasetsCall) {
      const { facets = [] } = dataResults || {}
      const facetsLists = getFacetsLists(facets as Facet[])

      updateFilterValue({
        ...facetsLists,
      }, currentPage)
      updateImpactDataset({
        makeInitDatasetsCall: false
      })
      updateDashboard({
        clearDatabaseSearchFilters
      })
    } else {
      const hasActiveColumnFilter = selectedLocations.length > 0 || selectedLocationRegions.length > 0 || selectedDatabases.length > 0 || selectedProperties.length > 0
      const hasActiveCommonCategory = selectedCommonCategories.some(category => category.values.length > 0)
      const hasActiveDatabaseCategory = selectedDatabaseCategories.some(category => category.values.length > 0) || selectedElementaryFlowCategories.some(category => category.values.length > 0)
      const sideOrTopFilterEnabled = hasActiveColumnFilter || hasActiveCommonCategory || hasActiveDatabaseCategory
      const searchOrFilterEnabled = sideOrTopFilterEnabled || !!query
      const items = dataResults?.items || []
      const totalItems = dataResults?.pagination?.totalResults || 0

      const updatedFacetsLists = updateFacetsListsCounts(dataResults?.facets, databaseList, locationList, locationRegionList, propertyList, commonCategoryList, databaseCategoryList)

      updateFilterValue({
        ...updatedFacetsLists,
        totalItems: searchOrFilterEnabled ? totalItems : 0,
        commonCategoryList: updatedFacetsLists.commonCategoryList
      }, currentPage)
      updateImpactDataset({
        referenceProducts: searchOrFilterEnabled ? items : [],
        initResultsAreLoading: false,
      })
    }
  }

  const initVariables = {
    query: '',
    impactMethodId,
    categoryId: '',
    pagination: {
      current: 1,
      size: PAGINATION.PAGE_SIZE,
    },
    searchType: SearchType.Exact,
    properties: [],
  }

  const sharedVariables = {
    query,
    impactMethodId,
    categoryId: '',
    pagination: {
      current: currentPage,
      size: PAGINATION.PAGE_SIZE,
    },
    searchType: SearchType.Exact,
    properties: [],
  }

  const getDatasetProperties = () => {
    const datasetProperties = [
      {
        name: FilterDatasetType.Database,
        values: selectedDatabases
      },
      {
        name: FilterDatasetType.Location,
        values: selectedLocations
      },
      {
        name: FilterDatasetType.LocationRegion,
        values: selectedLocationRegions
      },
      {
        name: FilterDatasetType.FlowProperty,
        values: selectedProperties
      },
      ...selectedDatabaseCategories.filter(category => category.values.length > 0),
      ...selectedCommonCategories.filter(category => category.values.length > 0)
    ]
    return datasetProperties
  }

  const getSortBy = () => {
    if (!selectedSortOrder || !selectedSortBy) {
      return
    }

    return { field: selectedSortBy, order: selectedSortOrder }
  }

  const datasetVariables = {
    ...sharedVariables,
    properties: getDatasetProperties(),
    sortBy: getSortBy(),
  }
  const {
    loading: loadingSearchDatasets,
    data: searchDatasetsData,
  } = useQuery(SEARCH_DATASETS, {
    skip: type !== ReferenceProductType.ProductSystem,
    variables: makeInitDatasetsCall ? initVariables : datasetVariables,
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!loadingSearchDatasets && searchDatasetsData) {
      handleFetchedData(searchDatasetsData?.searchDatasets)
    }
  }, [ loadingSearchDatasets, searchDatasetsData ])

  const defaultElementaryFlowProperties:FilterElementaryFlowProperty[] = [
    {
      name: FilterElementaryFlowType.D1,
      values: [ ELEMENTARY_FLOW_ROOT ]
    }
  ]

  const initVariablesElementaryFlow = {
    ...initVariables,
    properties: defaultElementaryFlowProperties
  }

  const elementaryFlowVariables = {
    ...sharedVariables,
    properties: [
      ...defaultElementaryFlowProperties,
      {
        name: FilterElementaryFlowType.FlowProperty,
        values: selectedFlowProperties
      },
      ...selectedElementaryFlowCategories.filter(category => category.values.length > 0),
    ],
    sortBy: getSortBy()
  }
  const {
    loading: loadingElementaryFlows,
    data: searchElementaryFlowsData
  } = useQuery(SEARCH_ELEMENTARY_FLOWS, {
    skip: type !== ReferenceProductType.ElementaryFlow,
    variables: makeInitDatasetsCall ? initVariablesElementaryFlow : elementaryFlowVariables,
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!loadingElementaryFlows && searchElementaryFlowsData) {
      handleFetchedData(searchElementaryFlowsData?.searchElementaryFlows)
    }
  }, [ loadingElementaryFlows, searchElementaryFlowsData ])

  return (
    <>
      { children }
    </>
  )
}
