import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkLarge, faPlus } from '@fortawesome/pro-regular-svg-icons'

import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import { EComparisonDatasetColumn, EComparisonDatasetItem } from '../enum/impact-dataset-column'
import { IReferenceProduct } from '../../model'
import { FormattedContentComponent } from './formatted-content.component'
import { useDatasetNavigate } from '../hook/use-dataset-navigate'
import { AddImpactDatasetContainer } from '../container/add-impact-dataset.container'
import { getNavigationStorageItem, updateNavigationStorageItemData } from '../../shared/util/navigation'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { formatGeographyDescription, formatTimePeriodDescription } from '../util/content'

const AddImpactWrapper = styled.div`
  right: 3.73rem;
  bottom: 0px;
  width: calc(100% - 24rem);
  background: none;
  -webkit-box-shadow: inset 0px -200px 300px -140px rgba(255,255,255,1);
  -moz-box-shadow: inset 0px -200px 300px -140px rgba(255,255,255,1);
  box-shadow: inset 0px -200px 300px -140px rgba(255,255,255,1);

  .add-impact-btn-wrapper {
    width: 32.725%;
    height: 100%;
    padding: 1.5rem;
  }
`

const TableWrapper = styled.div`

  .p-datatable-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 !important;

    .p-datatable-tbody {
      tr {
        border-radius: 0.5rem;

        &:hover {
          box-shadow: none !important;
          background-color: var(--surface-a);
        }

        td {
          cursor: auto;
          align-content: start;
          padding: 1.5rem;
        }

        td:not(:last-child) {
          border-right: solid 2px var(--gray-50);
        }
      }
    }

    .p-datatable-thead {
      display: none;
    }

    .p-datatable-emptymessage {
      display:none !important;
    }
  }
`

type TDatasetDetail = {
  label?: string,
  datasetItemKey?: EComparisonDatasetItem,
  isEcoinventDatabase?: boolean,
  firstDataset?: string,
  secondDataset?: string,
  thirdDataset?: string
}

export const CompareDatasetDetailComponent = () => {
  const { t } = useTranslation([ 'impact-dataset', 'common' ])
  const { updateImpactDataset } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const { selectedEntity } = useContext<IDashboardContext>(DashboardContext)
  const [ comparisonTableData, setComparisonTableData ] = useState<TDatasetDetail[]>([])

  const { datasetNavigate, inventoryItemId, activeTab } = useDatasetNavigate()
  const { data: { comparisonDatasets = [] } = {} } = (inventoryItemId && getNavigationStorageItem(inventoryItemId)) || {}

  const isDatabaseSearch = activeTab === ImpactDatasetViewType.DatabaseSearch

  useEffect(() => {
    if (inventoryItemId && comparisonDatasets.length > 0) {
      setComparisonTableData(getComparisonTableData(comparisonDatasets))
      activeTab && updateImpactDataset({
        selectedDatasetViewType: activeTab as ImpactDatasetViewType,
        referenceProducts: comparisonDatasets
      })
    }
  }, [ inventoryItemId ])

  const datasetColumnValues = Object.values(EComparisonDatasetColumn)

  type TRowItem = { datasetItemKey: EComparisonDatasetItem, label: string }
  const getDatasetRowItems = (): TRowItem[] => ([
    { datasetItemKey: EComparisonDatasetItem.DatasetName, label: isDatabaseSearch ? t('labels.datasetName') : t('labels.elementaryFlowName') },
    { datasetItemKey: EComparisonDatasetItem.Geography, label: t('labels.geography') },
    { datasetItemKey: EComparisonDatasetItem.ReferenceProduct, label: t('labels.referenceProduct') },
    { datasetItemKey: EComparisonDatasetItem.ReferenceProperty, label: t('labels.property') },
    { datasetItemKey: EComparisonDatasetItem.Unit, label: t('labels.unit', { ns: 'common' }) },
    { datasetItemKey: EComparisonDatasetItem.Technology, label: t('labels.technology') },
    { datasetItemKey: EComparisonDatasetItem.Time, label: t('labels.time') },
    { datasetItemKey: EComparisonDatasetItem.DatabaseCategoryPath, label: t('labels.databaseCategoriesPath') },
    ...(isDatabaseSearch ? [
      { datasetItemKey: EComparisonDatasetItem.Database, label: t('labels.database') }
    ] : [
      { datasetItemKey: EComparisonDatasetItem.Compartment, label: t('labels.compartment') },
      { datasetItemKey: EComparisonDatasetItem.SubCompartment, label: t('labels.compartment', { context: 'sub' }) }
    ]),
    { datasetItemKey: EComparisonDatasetItem.Description, label: t('labels.description', { ns: 'common' }) }
  ])

  const getDatasetContent = (datasetItem: IReferenceProduct) => {
    const {
      name,
      location,
      referenceProduct,
      referenceProperty,
      referenceUnit,
      technology,
      timeFrom,
      timeUntil,
      timeDescription,
      geography,
      databaseName,
      category,
      parentCategory,
      description,
      databaseCategoryPath,
    } = datasetItem
    const formattedTime = formatTimePeriodDescription(timeFrom, timeUntil, timeDescription)
    const formattedGeography = formatGeographyDescription(location?.name, geography)

    return {
      [EComparisonDatasetItem.DatasetName]: name,
      [EComparisonDatasetItem.Geography]: formattedGeography,
      [EComparisonDatasetItem.ReferenceProduct]: referenceProduct,
      [EComparisonDatasetItem.ReferenceProperty]: referenceProperty?.name,
      [EComparisonDatasetItem.Unit]: referenceUnit?.name,
      [EComparisonDatasetItem.Technology]: technology,
      [EComparisonDatasetItem.Time]: formattedTime,
      [EComparisonDatasetItem.Category]: category?.name,
      [EComparisonDatasetItem.DatabaseCategoryPath]: databaseCategoryPath,
      [EComparisonDatasetItem.Database]: databaseName,
      [EComparisonDatasetItem.Compartment]: parentCategory?.name,
      [EComparisonDatasetItem.SubCompartment]: category?.name,
      [EComparisonDatasetItem.Description]: description
    }
  }

  const getComparisonTableData = (datasets: IReferenceProduct[] = []): TDatasetDetail[] => {
    const datasetDetailList: TDatasetDetail[] = []

    const datasetRowItems = getDatasetRowItems()
    datasetRowItems.forEach((datasetRowItem: TRowItem, rowIndex: number) => {
      datasets.forEach((datasetItem: IReferenceProduct, index: number) => {
        const isEcoinventDatabase = datasetItem.databaseName?.toLowerCase()?.includes('ecoinvent')
        const { label, datasetItemKey } = datasetRowItem
        const datasetColumn = datasetColumnValues[index + 1]

        datasetDetailList[rowIndex] = {
          ...(index === 0 ? { label, datasetItemKey } : {}),
          ...datasetDetailList[rowIndex],
          [datasetColumn]: getDatasetContent(datasetItem)[datasetItemKey],
          isEcoinventDatabase
        }
      })

      const datasetDetailListItem = datasetDetailList[rowIndex]
      if (
        !datasetDetailListItem[EComparisonDatasetColumn.FirstDataset]
        && !datasetDetailListItem[EComparisonDatasetColumn.SecondaryDataset]
        && !datasetDetailListItem[EComparisonDatasetColumn.ThirdDataset]
      ) {
        datasetDetailList.splice(rowIndex, 1)
      }
    })

    return datasetDetailList
  }

  const updateStoredComparisonItems = (comparisonDatasets: IReferenceProduct[]) => {
    if (!inventoryItemId) return

    updateNavigationStorageItemData(inventoryItemId, { comparisonDatasets })
  }

  const removeDatasetItem = (column: EComparisonDatasetColumn) => {
    const [ , ...datasetColumnsWithoutLabel ] = datasetColumnValues
    const selectedIndex = datasetColumnsWithoutLabel.findIndex(item => item === column)
    if (selectedIndex < 0) return

    const updatedComparisonItems = [ ...comparisonDatasets ]
    updatedComparisonItems.splice(selectedIndex, 1)

    updateStoredComparisonItems(updatedComparisonItems)
    setComparisonTableData(getComparisonTableData(updatedComparisonItems))
    updateImpactDataset(isDatabaseSearch ? { comparisonDatabaseSearchItems: updatedComparisonItems } : { comparisonElementaryFlowItems: updatedComparisonItems })
  }

  const addDatasetToCompare = () => {
    updateImpactDataset(isDatabaseSearch ? { enableDatabaseSearchComparison: true } : { enableElementaryFlowComparison: true })
    datasetNavigate(isDatabaseSearch ? ImpactDatasetViewType.DatabaseSearch : ImpactDatasetViewType.ElementaryFlow)
  }

  const bodyTemplate = (rowData: TDatasetDetail, column: EComparisonDatasetColumn) => {
    const { datasetItemKey } = rowData
    const content = rowData[column]

    const searchType = isDatabaseSearch ? 'dataset' : 'elementaryFlow'

    if (column === EComparisonDatasetColumn.Label) {
      return <span className="text-sm font-semibold">{content}</span>
    }

    switch (datasetItemKey) {
    case EComparisonDatasetItem.Technology:
      return <FormattedContentComponent content={content} isEcoinventDatabase={rowData.isEcoinventDatabase} />
    case EComparisonDatasetItem.Description:
      return (
        <div className="flex w-full pb-7">
          <FormattedContentComponent content={content} header={t('labels.general', { context: 'comment' })} isEcoinventDatabase={rowData.isEcoinventDatabase} />
        </div>
      )
    case EComparisonDatasetItem.DatabaseCategoryPath:
      return <FormattedContentComponent content={content} isCategoryPath />
    case EComparisonDatasetItem.DatasetName:
      return (
        <>
          { content ? (
            <div className="flex w-full gap-3">
              <div className="flex flex-grow-1 text-sm font-semibold">{content}</div>
              { comparisonDatasets.length > 1 && (
                <FontAwesomeIcon data-testid="clear-comparison" onClick={() => removeDatasetItem(column)} icon={faXmarkLarge} className="text-base cursor-pointer hover:text-primary-500" />
              )}
            </div>
          ) : (
            <div className="flex flex-column w-full gap-3">
              <div className="flex w-full text-sm font-semibold">{ t('labels.noSelection', { context: searchType }) }</div>
              <div className="flex w-full h-2rem" onClick={addDatasetToCompare}>
                <Button className="p-button-outlined w-full text-sm align-items-center justify-content-center gap-3" onClick={() => addDatasetToCompare()}>
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="text-sm line-height-1">{ t('labels.addcompare', { context: searchType }) }</span>
                </Button>
              </div>
            </div>
          )}
        </>
      )
    }

    return <span className="text-sm">{content}</span>
  }

  return (
    <>
      <TableWrapper
        className="flex w-full h-full p-3 bg-white overflow-y-auto absolute"
        data-testid="datasets-search-results-table"
      >
        <DataTable value={comparisonTableData}>
          <Column field={EComparisonDatasetColumn.Label} style={{ width: '12rem' }} body={(rowData: TDatasetDetail) => bodyTemplate(rowData, EComparisonDatasetColumn.Label)} header={() => null} />
          <Column field={EComparisonDatasetColumn.FirstDataset} body={(rowData: TDatasetDetail) => bodyTemplate(rowData, EComparisonDatasetColumn.FirstDataset)} header={() => null} />
          <Column field={EComparisonDatasetColumn.SecondaryDataset} body={(rowData: TDatasetDetail) => bodyTemplate(rowData, EComparisonDatasetColumn.SecondaryDataset)} header={() => null} />
          <Column field={EComparisonDatasetColumn.ThirdDataset} body={(rowData: TDatasetDetail) => bodyTemplate(rowData, EComparisonDatasetColumn.ThirdDataset)} header={() => null} />
        </DataTable>
      </TableWrapper>

      { selectedEntity && (
        <AddImpactWrapper className="flex h-5rem fixed">
          { comparisonDatasets.map((datasetItem: IReferenceProduct, index: number) => (
            <div key={`add-impact-btn-wrapper-${index}`} className="add-impact-btn-wrapper">
              <AddImpactDatasetContainer referenceProductID={datasetItem.id} isFromDatasetDetail />
            </div>
          ))}
        </AddImpactWrapper>
      )}
    </>
  )
}
