import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IDashboardContext } from '../../interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { BackLinkComponent } from './back-link.component'
import { useIsMounted } from '../../hook/use-is-mounted'
import { TooltipOverflowContent } from '../../hook/use-ref-overflow'

const HeaderWrapper = styled.div`

  .root-object-icon {
    color: var(--primarydark-400);
    background: var(--primarydark-50);
    border: 1px solid var(--primarydark-100);
  }
`

export type THeaderComponentProps = {
  children?: React.ReactNode;
  defaultBackTo?: string;
  label: string;
  icon: React.ReactNode;
}

export const HeaderComponent = ({
  children,
  defaultBackTo,
  label,
  icon,
}: THeaderComponentProps) => {
  const { t } = useTranslation([ 'common' ])
  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const [ backTo, setBackTo ] = useState<string | undefined | null>(null)
  const { backToList = [], removeFromBackToList = () => {}, updateDashboard } = useContext<IDashboardContext>(DashboardContext)

  useEffect(() => {
    backToList.length > 0 && isMounted()
      ? setBackTo(backToList[backToList.length - 1])
      : setBackTo(null)
  }, [ backToList ])

  const handleBack = () => {
    navigate(-1)
    removeFromBackToList()
    updateDashboard({ reopenSidebar: true })
  }

  const backToLabel = backTo ? t('labels.backTo', { backTo }) : t('labels.backTo', { backTo: defaultBackTo })
  const getTooltipContent = () => (
    <TooltipOverflowContent>
      {' '}
      { backToLabel }
      {' '}
    </TooltipOverflowContent>
  )
  return (
    <HeaderWrapper>
      <div data-cy="go-back-button" className="flex align-items-center justify-content-start h-4rem">
        <BackLinkComponent
          handleBack={handleBack}
          tooltipContent={getTooltipContent()}
          backButtonLabel={backToLabel}
        />
      </div>
      <div className="flex align-items-center justify-content-start h-3rem">
        <div
          className="flex align-items-center justify-content-center border-1 border-round h-3rem w-3rem px-3 text-primary-500 bg-primary-50 border-primary-100"
          data-testid="header-detail-icon"
        >
          {icon}
        </div>
        <div
          data-cy="header-title"
          data-testid="header-detail-name"
          className="flex-grow-1 text-gray-800 text-3xl font-medium px-3 white-space-nowrap overflow-hidden text-overflow-ellipsis py-1 page-header-title"
        >
          {label}
        </div>
        {children}
      </div>
    </HeaderWrapper>
  )
}
