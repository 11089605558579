import React, { useContext, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'

import { REMOVE_PRODUCT_REFERENCE } from '../../../graphql/mutation'
import { displayGraphqlErrors } from '../../../shared/util/error'
import { DeleteReferenceProductComponent } from '../../component/dialog/delete-reference-product.component'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { IProduct } from '../../../model'

type DeleteReferenceProductContainerProps = {
  afterDeleteTreeItem: () => void
}

export const DeleteReferenceProductContainer = ({
  afterDeleteTreeItem
}: DeleteReferenceProductContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const toast = useRef<Toast>(null)
  const {
    selectedEntity,
    showDeleteReferenceProduct = false,
    updateDashboard
  } = useContext(DashboardContext)

  const selectedProduct = selectedEntity as IProduct
  const [
    removeProductReference,
    {
      error: failedRemovingReferenceProduct,
      data: removeProductReferenceData,
      loading: removingReferenceProduct
    }
  ] = useMutation(REMOVE_PRODUCT_REFERENCE)
  const removedReferenceProduct = removeProductReferenceData?.removeProductReference

  useEffect(() => {
    if (!removingReferenceProduct && (!!removedReferenceProduct || failedRemovingReferenceProduct)) {
      removeImpactProductCallback()
    }
  }, [ removingReferenceProduct, !!removedReferenceProduct, failedRemovingReferenceProduct ])

  const closeDeleteDialog = () => {
    updateDashboard({ showDeleteReferenceProduct: false })
  }

  const handleDeleteReferenceProduct = () => {
    selectedProduct?.id && removeProductReference({ variables: { productID: selectedProduct.id } })
  }

  const removeImpactProductCallback = () => {
    try {
      if (failedRemovingReferenceProduct) {
        throw failedRemovingReferenceProduct
      } else if (removedReferenceProduct) {
        afterDeleteTreeItem()
        closeDeleteDialog()
        updateDashboard({ selectedEntity: { ...selectedProduct, referenceProduct: null } })
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.success', { context: 'removeImpactProduct', ns: 'product' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.errorSummary', { context: 'removeImpactProduct', ns: 'product' }), error?.graphQLErrors)
    }
  }

  return (
    <>
      <DeleteReferenceProductComponent
        removingInProgress={removingReferenceProduct}
        handleDelete={handleDeleteReferenceProduct}
        closeDialog={closeDeleteDialog}
        showDialog={showDeleteReferenceProduct}
      />

      <Toast data-testid="delete-product-status" ref={toast} position="top-right" />
    </>
  )
}
