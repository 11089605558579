import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faGrid2 } from '@fortawesome/pro-regular-svg-icons';

import { IDashboardContext } from '../../shared/interface/workspace-context-type';
import DashboardContext from '../../dashboard/context/dashboard.context';
import { IProduct } from '../../model';
import { ProductLabel } from '../../shared/enum';
import { DetailPanelHeaderComponent } from '../../shared/component/general/detail-panel-header.component';
import { ItemDetailContainer } from '../container/detail-panel/item-detail.container';
import { ImpactPreviewComponent } from '../component/detail-panel/view-template/impact-preview.component';

export const ProductDetailPanelLayout = () => {
  const { t } = useTranslation([ 'product', 'common', 'impact-dataset' ]);
  const { selectedEntity, updateDashboard, onSidebarHide = () => {} } = useContext<IDashboardContext>(DashboardContext);
  const { labels = [], name } = selectedEntity as IProduct || {}
  const [ productLabel ] = labels
  const isProduct = productLabel?.id === ProductLabel.Product
  const selectedProduct = selectedEntity as IProduct

  const afterCloseDetailPanel = () => {
    updateDashboard({ selectedEntity: null })
    onSidebarHide({}, true)
  }

  return (
    <div className="flex flex-column w-full h-full">
      <div className="flex w-full h-5rem">
        {
          name && (
            <DetailPanelHeaderComponent
              title={t('labels.detailPanel', { ns: 'common' })}
              afterCloseDetailPanel={afterCloseDetailPanel}
              icon={<FontAwesomeIcon icon={isProduct ? faTag : faGrid2} className="text-xl" />}
            />
          )
        }
      </div>

      <div className="flex flex-column w-full bg-primary-50 py-4">
        <ImpactPreviewComponent />
      </div>

      <div className="flex flex-grow-1 pb-6">
        <div className="flex flex-column w-full">
          <div className="flex w-full align-items-center justify-content-start text-base font-medium p-4 pb-3">
            { t('labels.itemDetails') }
          </div>
          <div className="flex w-full px-3">
            <ItemDetailContainer selectedProduct={selectedProduct} />
          </div>
        </div>

      </div>
    </div>
  );
}
