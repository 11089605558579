import React, { useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'

import { FilterDatasetType } from '../../../../__generated__/graphql'
import ImpactDatasetContext from '../../provider/context/impact-dataset.context'
import { CommonCategoryGroup } from './common-category-group.component'
import { CATEGORY_TITLES_MAP } from '../../constant/categories'
import { TImpactDatasetContext, TPageData } from '../../interface/impact-dataset.context'

export const CommonCategoriesFilterComponent = () => {
  const { t } = useTranslation('impact-dataset')
  const [ showCategories, setShowCategories ] = useState(true)
  const {
    updateFilterValue,
    getDatasetPageData = () => ({} as TPageData),
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const { commonCategoryList = [], selectedCommonCategories = [] } = getDatasetPageData()

  const onCategoriesChange = (categoryName: FilterDatasetType, updateValues: string[]) => {
    let updatedSelectedCommonCategories = [
      ...selectedCommonCategories.filter(category => category.name !== categoryName),
      {
        name: categoryName,
        values: updateValues
      }
    ]
    if (categoryName === FilterDatasetType.C0) {
      if (updateValues.length === 0) {
        updatedSelectedCommonCategories = []
      } else {
        const rootCategoryItems = commonCategoryList.find(category => category.name === FilterDatasetType.C0)?.values || []

        const allItemsHaveZeroCount = updateValues.every(value => {
          const categoryItemCount = rootCategoryItems.find(item => item.name === value)?.count || 0
          return categoryItemCount === 0
        })

        if (allItemsHaveZeroCount) {
          updatedSelectedCommonCategories = [ { name: FilterDatasetType.C0, values: updateValues } ]
        }
      }
    }

    updateFilterValue({
      selectedCommonCategories: updatedSelectedCommonCategories
    })
  }

  const toggleCategories = () => setShowCategories(!showCategories)
  const someCommonCategoriesSelected = selectedCommonCategories.some(category => category.values.length > 0)

  const endIndex = someCommonCategoriesSelected ? commonCategoryList.length - 1 : 1;
  const commonCategoriesToShow = commonCategoryList.slice(0, endIndex);

  return (
    <div>
      <div className="flex w-full align-items-center py-1 border-bottom-1 border-gray-100 gap-2 text-gray-500">
        <div
          data-testid="parent-category-name"
          className="flex flex-grow-1 align-items-center text-base font-medium text-gray-500 line-height-2 cursor-pointer"
          onClick={toggleCategories}
        >
          {t('labels.categories')}
        </div>
        <div data-testid="toggle-show-categories" className="flex w-1rem align-items-center text-gray-500 cursor-pointer" onClick={toggleCategories}>
          <FontAwesomeIcon icon={showCategories ? faChevronDown : faChevronUp} className="text-md" />
        </div>
      </div>
      {showCategories && (
        <div className="mt-2">
          {commonCategoriesToShow.map(category => {
            const selectedCategoryInfo = selectedCommonCategories.find(selectedCategory => selectedCategory.name === category.name) || { values: [] }
            return (
              <CommonCategoryGroup
                key={category.name}
                category={category}
                title={CATEGORY_TITLES_MAP[category.name]}
                onCategoriesChange={onCategoriesChange}
                selectedValues={selectedCategoryInfo.values}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
