import React, {
  useState, useEffect, useRef
} from 'react';

import styled, { createGlobalStyle } from 'styled-components';

import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Tooltip } from 'primereact/tooltip'
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { RadioButton } from 'primereact/radiobutton';
import { useForm, Controller } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea';
import { ColorPicker } from 'primereact/colorpicker';
import { Trans, useTranslation } from 'react-i18next';

import { IControllerRender } from '../../../shared/interface/react-form-hook';
import { Module, ProductType } from '../../../../__generated__/graphql';
import { TTag } from '../../../model';
import { TagViewModeComponent } from './view-template/tag-view-mode.component';
import { ItemDetailComponent } from '../../enum/impact-detail';

const GlobalStyle = createGlobalStyle`
  .module-dropdown-panel {
    .p-dropdown-item {
      width: 300px;
      white-space: normal;
    }
  }

  .tag-dropdown-menu {
    width: 15.5rem;
    max-height: 10rem;
    overflow: auto;
  }

  .item-view-label {
    min-height: 24px;
    max-height: 54px;
    overflow: hidden;
    line-height: 1.125rem;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: normal;
    -webkit-line-clamp: 'none';
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
`

const FormItemEditMode = styled.div`
  form {
    width: 100%;
  }

  .p-dropdown,
  input {
    width: 100%;
    height: 2rem;
  }

  .p-inputtext {
    padding: 0.25rem 0.5rem;
  }
`

type TTagMenuItem = { label?: string, value?: TTag }

type TImpactDetailFormItemProps = {
  label: string,
  item: ItemDetailComponent,
  value?: any,
  handleFormSubmit: Function,
  editMode: boolean,
  setEditMode: Function,
  modules?: Module[],
  existingTags?: TTag[]
}
export const ItemDetailFormComponent = ({
  label,
  item,
  value = '-',
  handleFormSubmit,
  editMode,
  setEditMode,
  modules = [],
  existingTags = []
}: TImpactDetailFormItemProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const formRef = useRef<HTMLFormElement>(null)
  const menuRef = useRef<Menu>(null);
  const [ isMouseOver, setIsMouseOver ] = useState<boolean>(false)
  const [ tagDropdownItems, setTagDropdownItems ] = useState<TTagMenuItem[]>([])

  const isItem = (param: ItemDetailComponent) => item === param

  let defaultValues = { [item]: value }
  if (isItem(ItemDetailComponent.Module)) {
    defaultValues = { [item]: value?.code || '' }
  } else if (isItem(ItemDetailComponent.Tags)) {
    defaultValues = { color: '#0B79CA' }
  }

  const {
    control, handleSubmit, reset, setValue, formState: { errors = {} }
  } = useForm({ defaultValues })

  useEffect(() => {
    reset(defaultValues)
  }, [ isMouseOver ])

  useEffect(() => {
    if (isItem(ItemDetailComponent.Tags) && existingTags.length > 0) {
      setTagDropdownItems(getTagDropdownItems())
    }
  }, [ existingTags ])

  const onSubmit = (data: any) => {
    setIsMouseOver(false)
    handleFormSubmit(data, item)
  }

  const executeCommand = (event: any) => {
    const { value: tag } = event.item
    tag && onSubmit({ tag })
  }

  const getTagDropdownItems = (filterBy?: string): TTagMenuItem[] => {
    const tags = filterBy
      ? existingTags.filter(tag => tag.name?.includes(filterBy))
      : existingTags

    return tags.map(tag => ({
      label: tag.name,
      value: tag,
      command: executeCommand
    }))
  }

  const enableTagEdit = () => {
    reset(defaultValues)
    setEditMode(true)
  }

  const backToViewMode = () => {
    setIsMouseOver(false)
    setEditMode(false)
  }

  const submitOrCancelOnKeyUp = (e: any, useShiftForNewline: boolean = false) => {
    if (!(e.shiftKey && useShiftForNewline) && e.key === 'Enter') {
      e.target.form.requestSubmit();
    } else if (e.key === 'Escape') {
      backToViewMode()
    }
  }

  const getErrorMessage = (context: ItemDetailComponent): string => t('messages.errorMessage', { context })

  const isViewMode = !editMode && !isMouseOver
  const disabled = isMouseOver && !editMode
  const moduleDropdownItems = modules.map((module: Module) => ({ label: t('labels.module', { context: module.code, ns: 'common' }), value: module.code }))
  const tags: TTag[] = isItem(ItemDetailComponent.Tags) && value !== '-' ? value : []

  const renderItemFormComponent = () => {
    const renderController = (
      render: (controllerRender: IControllerRender) => React.ReactElement,
      rules?: Object
    ) => (
      <>
        <Controller name={item} control={control} rules={rules} render={render} />
        { errors[item] && <small className="p-error">{ errors[item]?.message }</small> }
      </>
    );

    const inputField = ({ field, fieldState }: IControllerRender) => (
      <InputText
        id={field[item]}
        {...field}
        autoFocus
        placeholder={label}
        disabled={disabled}
        autoComplete="off"
        onKeyUp={(e: any) => {
          if (e.key === 'Escape') backToViewMode()
        }}
        className={classNames('w-full', { 'p-invalid': fieldState.error })}
      />
    )

    const descriptionTextarea = ({ field }: IControllerRender) => (
      <InputTextarea
        id={field[item]}
        {...field}
        autoFocus
        placeholder={label}
        disabled={disabled}
        onKeyUp={(e: any) => submitOrCancelOnKeyUp(e, true)}
        rows={3}
        autoResize
        className="w-full"
      />
    )

    const moduleDropdown = ({ field, fieldState }: IControllerRender) => (
      <Dropdown
        id={field[item]}
        {...field}
        options={[
          { label: t('labels.noModule'), value: '' },
          ...moduleDropdownItems
        ]}
        panelClassName="module-dropdown-panel"
        disabled={disabled}
        onChange={(e: any) => {
          field.onChange(e)
          setIsMouseOver(false)
          formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }}
        className={classNames('w-full', { 'p-invalid': fieldState.error })}
      />
    )

    const typeRadioButtonGroup = (
      <div className="flex w-full gap-3 pt-2">
        {
          Object.values(ProductType).map((productType, index) => (
            <div key={`product-type-radiobutton-key-${index}`} className="flex w-max align-items-center gap-1">
              { renderController(({ field, fieldState }: IControllerRender) => (
                <RadioButton
                  id={field[item]}
                  {...field}
                  value={productType}
                  onChange={() => {
                    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                  }}
                  checked={value === productType}
                  disabled={disabled}
                  className={classNames('w-max', { 'p-invalid': fieldState.error })}
                />
              ))}
              <label
                htmlFor={productType}
                className={classNames('text-sm', {
                  'text-gray-300 cursor-pointer': disabled,
                  'text-gray-700': !disabled
                })}
              >
                {t('labels.productType', { context: productType })}
              </label>
            </div>
          ))
        }
      </div>
    )

    const tagInput = (
      <div className="flex w-full gap-2 pt-2">
        <div className="flex w-2rem align-items-top justify-content-center">
          <Controller
            name="color"
            control={control}
            render={({ field }: IControllerRender) => (
              <ColorPicker {...field} onChange={(e: any) => { setValue('color', `#${e.value}`) }} id="color" disabled={disabled} />
            )}
          />
        </div>
        <div className="flex-grow-1 align-items-center justify-content-center">
          <Menu
            model={tagDropdownItems}
            popup
            ref={menuRef}
            id="popup_menu"
            className="tag-dropdown-menu"
          />
          <Controller
            name="name"
            control={control}
            rules={{ required: getErrorMessage(item) }}
            render={({ field, fieldState }: IControllerRender) => (
              <InputText
                id="name"
                {...field}
                autoFocus
                placeholder="Name"
                autoComplete="off"
                onClick={(event: any) => menuRef.current?.show(event)}
                onChange={(event: any) => {
                  field.onChange(event)
                  setTagDropdownItems(getTagDropdownItems(event.target?.value))
                }}
                onKeyUp={submitOrCancelOnKeyUp}
                className={classNames('w-full', { 'p-invalid': fieldState.error })}
                disabled={disabled}
              />
            )}
          />
          { errors?.name && <small className="p-error">{ errors?.name?.message }</small> }
        </div>
      </div>
    )

    switch (item) {
    case ItemDetailComponent.Unit:
      return renderController(inputField, { required: getErrorMessage(item) })
    case ItemDetailComponent.Name: {
      const rule = {
        required: getErrorMessage(item),
        minLength: { value: 2, message: t('messages.errorMessage', { context: 'nameMinLength' }) }
      }
      return renderController(inputField, rule)
    } case ItemDetailComponent.Description:
      return renderController(descriptionTextarea)
    case ItemDetailComponent.Module:
      return renderController(moduleDropdown)
    case ItemDetailComponent.Type:
      return typeRadioButtonGroup
    case ItemDetailComponent.Tags:
      return tagInput
    }
  }

  const getTooltipContent = () => {
    switch (item) {
    case ItemDetailComponent.Tags:
      return t('labels.itemDetailDesc', { context: 'addTag' })
    case ItemDetailComponent.Description:
      return t('labels.itemDetailDesc', { context: 'description' })
    case ItemDetailComponent.Type:
      return <Trans t={t} ns="product" i18nKey="labels.itemDetailDesc_type" />
    }
  }

  const renderItemViewComponent = () => {
    const isItemTagsOrModule = isItem(ItemDetailComponent.Module) || isItem(ItemDetailComponent.Tags)
    const label = !isItemTagsOrModule && value && value.trim() ? value : '-'

    switch (item) {
    case ItemDetailComponent.Module: {
      const selectedModuleLabel = moduleDropdownItems.find((item: any) => item.value === value?.code)?.label || t('labels.noModule')
      return <>{ selectedModuleLabel }</>
    } case ItemDetailComponent.Tags:
      return <TagViewModeComponent tags={tags} handleFormSubmit={handleFormSubmit} enableTagEdit={enableTagEdit} />
    }

    return <div className="item-view-label">{label}</div>
  }

  const showInfoIcon = isItem(ItemDetailComponent.Type) || isItem(ItemDetailComponent.Tags) || isItem(ItemDetailComponent.Description)

  return (
    <>
      <GlobalStyle />
      <Tooltip target={`#item-detail-info-${item}`} position="left"><div className="block w-15rem">{getTooltipContent()}</div></Tooltip>

      <div className="flex align-items-center text-xs text-gray-300 gap-1">
        <span>{ label }</span>
        { showInfoIcon && <FontAwesomeIcon icon={faCircleInfo} className="text-sm cursor-pointer hover:text-primary-500" id={`item-detail-info-${item}`} /> }
      </div>
      { isViewMode && (
        <div
          onMouseEnter={() => !isItem(ItemDetailComponent.Tags) && setIsMouseOver(true)}
          className="flex align-items-center text-sm text-gray-700 w-full"
        >
          { renderItemViewComponent() }
        </div>
      )}
      { !isViewMode && (
        <FormItemEditMode
          className={classNames('flex w-full', { 'cursor-pointer': disabled })}
          onMouseLeave={() => setIsMouseOver(false)}
          onClick={() => setEditMode(true)}
        >
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            { renderItemFormComponent() }
          </form>
        </FormItemEditMode>
      )}
    </>
  )
}
