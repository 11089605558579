import { gql } from '../../../__generated__'

export const REMOVE_CONVERSION_ITEM = gql(`
  mutation removeConversionItem($conversionID: ID!, $productID: ID!) {
    removeConversionItem(conversionID: $conversionID, productID: $productID)
  }
`)

export const CHANGE_INVENTORY_ITEM_AMOUNT = gql(`
  mutation changeInventoryItemAmount($inventoryItemID: ID!, $inventoryItemAmount: String!, $targetID: String!, $targetAmount: String!) {
    changeInventoryItemAmount(inventoryItemID: $inventoryItemID, inventoryItemAmount: $inventoryItemAmount, targetID: $targetID, targetAmount: $targetAmount){
      ...extendedProductFragment
    }
  }
`)

export const ADD_INVENTORY_ITEM = gql(`
  mutation addInventoryItem($productID: ID!, $productAmount: String!, $inventoryItemID: ID!, $inventoryItemAmount: String!) {
    addInventoryItem(productID: $productID, productAmount: $productAmount, inventoryItemID: $inventoryItemID, inventoryItemAmount: $inventoryItemAmount){
      ...extendedProductFragment
    }
  }
`)

export const CREATE_INVENTORY_ITEM = gql(`
  mutation createInventoryItem($productID: ID!, $productAmount: String!, $name: String!, $value: String!, $unit: String, $isWaste: Boolean!) {
    createInventoryItem(productID: $productID, productAmount: $productAmount, name: $name, value: $value, unit: $unit, isWaste: $isWaste){
      ...extendedProductFragment
    }
  }
`)
