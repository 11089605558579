import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../../utils/with-router'
import { useNavigate } from 'react-router-dom'
import compose from '../../../../utils/compose'
import { TRACK_EVENT_TYPE, IMPACT_CALCULATION_STATUS, TIME } from '../../../../utils/const'
import { goToInventoryRootProduct, goToLifecycleRoot, goToLifecycleList } from '../../../../utils/goBackNavigation'
import { formatLifecycleName } from '../../../../utils/tools'
import { Frame } from '../../../layout'
import Lifecycle from '../lifecycle.container'
import PageHeader from '../../page-header/page-header'
import ProductTools from '../../product-tools/productTools.container'
import { tabChartView, tabFlatView, tabSunburst, tabTreeView } from '../../../shared/locations'
import LifecycleDetailPanelWrapper from '../lifecycle-detail-panel-wrapper/lifecycleDetailPanelWrapper.container'
import DetailsPanel from '../../details-panel/detailsPanel.container'
import ImpactSelectorDialog from '../../../helpers/dialogs/impactSelectorDialog/impactSelectorDialog.container'

const LifecycleItem  = props => {
  const {
    lifecycleId,
    activeTabKey,
    trackEventSaga,
    loadLifecycleItemSaga,
    setIsForcingWorkspacePanelAction,
    setSelectedLifecycleAction,
    setIsCreateLifecycleAction,
    setIsCreatePhaseAction,
    setSelectedPhaseAction,
    setSelectedProductAction,
    setFlatViewAction,
    setInventoryLifecyclesAction,
    setIsOrderingPhasesAction,
    updateLifecyclesItemsSaga,
    workspaceSlug,
    selectedAccountId,
    getSpaceBySlugWithProductsSaga,
    selectedLifecycleName,
    selectedLifecycleAmount,
    selectedLifecycleUnit,
    lifecycleProductName,
    t,
    impactCategoryId,
    setIsDetailsPanelOpenAction,
    inventoryTotalImpactStatus,
    productMutator,
    selectedProductId,
    setSelectedInventoryItemKeyAction,
    excludeLT,
    useMethodTotal,
    nwSetId,
  } = props

  let tabTrackByKey

  const navigate = useNavigate()
  useEffect(() => {
    const trackAction = tab => {
      trackEventSaga(TRACK_EVENT_TYPE.VIEWED_LIFECYCLE, { view: tab })
    }

    tabTrackByKey = new Map([
      [ tabTreeView, () => trackAction(TRACK_EVENT_TYPE.VIEWED_TREEVIEW) ],
      [ tabFlatView, () => trackAction(TRACK_EVENT_TYPE.VIEWED_FLATVIEW) ],
      [ tabChartView, () => trackAction(TRACK_EVENT_TYPE.VIEWED_BARCHART) ],
      [ tabSunburst, () => trackAction(TRACK_EVENT_TYPE.VIEWED_SUNBURST) ]
    ])

    loadWorkspace()
    loadLifecycleItemSaga({ lifecycleId })

    if (props.selectedProductId || props.selectedPhaseId || props.isCreatePhase) {
      setIsForcingWorkspacePanelAction(false)
    }
    setIsDetailsPanelOpenAction(false)
    return () => {
      componentCleanup()
      setIsDetailsPanelOpenAction(false)
    }
  }, [])

  useEffect(() => {
    const lifecycleIntervalUpdate = updateLifecycleEachMinute()
    return () => {
      clearInterval(lifecycleIntervalUpdate)
    }
  }, [ inventoryTotalImpactStatus ])

  useEffect(() => {
    trackTabChange(activeTabKey)
  }, [ activeTabKey ])
  useEffect(() => { loadWorkspace() }, [ workspaceSlug ])
  useEffect(() => {
    loadLifecycleItemSaga({ lifecycleId })
    setIsForcingWorkspacePanelAction(false)
  }, [ lifecycleId, impactCategoryId, excludeLT, useMethodTotal, nwSetId ])

  const componentCleanup = () => {
    setSelectedLifecycleAction(null)
    setIsCreateLifecycleAction(false)
    setIsCreatePhaseAction(false)
    setSelectedPhaseAction(null)
    setSelectedProductAction(null)
    setFlatViewAction(null)
    setInventoryLifecyclesAction([])
    setIsOrderingPhasesAction(false)
  }

  const updateLifecycleEachMinute = () => {
    return setInterval(() => {
      if (inventoryTotalImpactStatus !== IMPACT_CALCULATION_STATUS.OK) {
        updateLifecyclesItemsSaga()
      }
    }, TIME.FIVE_MINUTES)
  }

  const trackTabChange = activeTabKey => {
    const trackAction = tabTrackByKey && tabTrackByKey.get(activeTabKey)
    trackAction && trackAction()
  }

  const loadWorkspace = () => {
    getSpaceBySlugWithProductsSaga(workspaceSlug, selectedAccountId)
  }

  const getHeaderTitleClickAction = () => {
    return () => {
      setIsCreateLifecycleAction(false)
      setIsCreatePhaseAction(false)
      setSelectedPhaseAction(null)
      setSelectedProductAction(null)
      setIsForcingWorkspacePanelAction(false)
      setSelectedInventoryItemKeyAction(null)
      setIsDetailsPanelOpenAction(true)
    }
  }

  const getPageHeaderProps = () => {
    const pageHeaderData = {
      goToPreviousPage: lifecycleProductName ? goToInventoryRootProduct : goToLifecycleList,
      previousNavigationStateName: lifecycleProductName || t('model.lifecycles'),
      onClick: getHeaderTitleClickAction(),
      headerTitle: formatLifecycleName(selectedLifecycleName, selectedLifecycleAmount, selectedLifecycleUnit, t('global.of')),
      addCustomButton: <ProductTools />,
    }

    return pageHeaderData
  }

  return <Frame>
    { () => <React.Fragment>
      <Row>
        <Col span={24} className='inventory middle-panel' data-cy="middle-panel-for-lifecycle">
          <PageHeader { ...getPageHeaderProps() }/>
          <section className='page-container'>
            <Lifecycle navigate={navigate} activeTabKey={ activeTabKey }/>
          </section>
        </Col>
      </Row>
      <DetailsPanel>
        <LifecycleDetailPanelWrapper updatePageItems={updateLifecyclesItemsSaga}/>
      </DetailsPanel>
      { selectedProductId && <ImpactSelectorDialog
        productMutator={ productMutator }
        updatePageItems={ updateLifecyclesItemsSaga }
      />
      }
    </React.Fragment> }
  </Frame>

}

export { LifecycleItem }
export default compose(
  withTranslation(),
  withRouter
)(LifecycleItem)
