import { Facet, FacetValue, FilterDatasetType } from '../../../__generated__/graphql'
import { TPageData } from '../interface/impact-dataset.context'

type CountsDictionary = {
  [key: string]: number
}

export const getFacetsCountsDictionary = (facet: Facet): CountsDictionary => facet.values.reduce((acc, value) => ({
  ...acc,
  [value.name]: value.count
}), {})

const getSortedCategories = (categoryList: Facet[] = []) => {
  const withSortedValues = [ ...categoryList ].map(category => {
    const categoryValues = [ ...category.values ]
    return ({
      ...category,
      values: categoryValues.sort((a, b) => {
        if (a.name.toLowerCase() === 'other') return 1
        if (b.name.toLowerCase() === 'other') return -1

        return a.name.localeCompare(b.name)
      })
    })
  })

  return [ ...withSortedValues ].sort((a, b) => {
    const orderNumA = Number(a.name.slice(1))
    const orderNumB = Number(b.name.slice(1))
    return orderNumA - orderNumB
  })
}

const isCommonCategory = (facet:Facet) => facet.name.charAt(0) === 'c'
const isDatabaseCategory = (facet:Facet) => facet.name.charAt(0) === 'd'

export const getFacetsLists = (facets: Facet[] = []) => {
  const facetsLists: Partial<TPageData> = {
    commonCategoryList: [],
    databaseCategoryList: []
  }

  facets.forEach(facet => {
    switch (facet.name) {
    case FilterDatasetType.Database:
      facetsLists.databaseList = facet.values
      break
    case FilterDatasetType.FlowProperty:
      facetsLists.propertyList = [ ...facet.values ].sort((a, b) => a.name.localeCompare(b.name))
      break
    case FilterDatasetType.Location: {
      facetsLists.locationList = facet.values
      break
    }
    case FilterDatasetType.LocationRegion: {
      const sorted = [ ...facet.values ].sort((a, b) => {
        if (a.name === 'Global') return -1;
        if (b.name === 'Global') return 1;
        return a.name.localeCompare(b.name);
      })
      facetsLists.locationRegionList = sorted
      break
    }
    default: {
      const isNotEmpty = facet.values.length > 0

      if (isNotEmpty) {
        if (isCommonCategory(facet)) {
          facetsLists.commonCategoryList?.push(facet)
        }
        if (isDatabaseCategory(facet)) {
          facetsLists.databaseCategoryList?.push(facet)
        }
      }
    }
    }
  })

  facetsLists.commonCategoryList = getSortedCategories(facetsLists.commonCategoryList)
  facetsLists.databaseCategoryList = getSortedCategories(facetsLists.databaseCategoryList)

  return facetsLists
}

const updateCountsInList = (list: FacetValue[], newCounts: CountsDictionary) => list.map(item => ({
  ...item,
  count: newCounts[item.name] || 0
}))

export const updateFacetsListsCounts = (
  facets: Facet[] = [],
  databaseList: FacetValue[] = [],
  locationList: FacetValue[] = [],
  locationRegionList: FacetValue[] = [],
  propertyList: FacetValue[] = [],
  commonCategoryList: Facet[] = [],
  databaseCategoryList: Facet[] = [],
) => {
  const facetsLists: Partial<TPageData> = {
    commonCategoryList: [],
    databaseCategoryList: []
  }

  facets.forEach(facet => {
    const newCounts = getFacetsCountsDictionary(facet)

    switch (facet.name) {
    case FilterDatasetType.Database:
      facetsLists.databaseList = updateCountsInList(databaseList, newCounts)
      break
    case FilterDatasetType.Location:
      facetsLists.locationList = updateCountsInList(locationList, newCounts)
      break
    case FilterDatasetType.LocationRegion:
      facetsLists.locationRegionList = updateCountsInList(locationRegionList, newCounts)
      break
    case FilterDatasetType.FlowProperty:
      facetsLists.propertyList = updateCountsInList(propertyList, newCounts)
      break
    default: {
      if (isCommonCategory(facet)) {
        const commonCategoryGroupValues = commonCategoryList.find(categoryGroup => categoryGroup.name === facet.name)?.values || []
        const commonCategoryCountList = updateCountsInList(commonCategoryGroupValues, newCounts)

        facetsLists.commonCategoryList?.push({
          ...facet,
          values: commonCategoryCountList
        })
      }
      if (isDatabaseCategory(facet)) {
        const databaseCategoryGroupValues = databaseCategoryList.find(categoryGroup => categoryGroup.name === facet.name)?.values || []
        const databaseCategoryCountList = updateCountsInList(databaseCategoryGroupValues, newCounts)

        facetsLists.databaseCategoryList?.push({
          ...facet,
          values: databaseCategoryCountList
        })
      }
    }
    }
  })

  return facetsLists
}

