import React, {
  useContext, useState, useEffect
} from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery, useMutation } from '@apollo/client'

import { POSSIBLE_INVENTORY_ITEMS } from '../../../graphql/query';
import { useHandleMutationResponse } from '../../../shared/hook/use-handle-mutation-response';
import { ADD_INVENTORY_ITEM, CREATE_INVENTORY_ITEM } from '../../../graphql/mutation';
import { TProductDetailContext } from '../../interface/product-detail-context.type';
import ProductDetailContext from '../../provider/context/product-detail.context';

type TAddSubItemFormContainerProps = {
  node?: any,
  onEscape?: (event: any) => void,
  ItemComponent: React.FC<any>
}
export const AddSubItemFormContainer = ({
  node = {},
  onEscape = () => {},
  ItemComponent
}: TAddSubItemFormContainerProps) => {
  const { t } = useTranslation([ 'product' ])
  const { handleResponse } = useHandleMutationResponse(t)
  const [ existingProducts, setExistingProducts ] = useState<any[]>([])
  const {
    refetchInventory = () => {},
    refetchInventoryItems = () => {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { data: { parent = {} } = {} } = node
  const { product: { id: productID = null } = {}, amount } = parent

  const {
    loading: loadingPossibleInventoryItems,
    data: possibleInventoryItemsResponse,
  } = useQuery(POSSIBLE_INVENTORY_ITEMS, {
    skip: !productID,
    variables: {
      productID
    },
    fetchPolicy: 'no-cache'
  });

  const { possibleInventoryItems } = possibleInventoryItemsResponse || {}
  useEffect(() => {
    if (!loadingPossibleInventoryItems && possibleInventoryItems) {
      setExistingProducts(possibleInventoryItems)
    }
  }, [ loadingPossibleInventoryItems, possibleInventoryItems ]);

  const [
    createInventoryItem,
    {
      error: failedCreatingInventoryItem,
      data: createInventoryItemResponse = {},
      loading: creatingInventoryItem
    }
  ] = useMutation(CREATE_INVENTORY_ITEM)

  const createdInventoryItem = createInventoryItemResponse?.createInventoryItem
  useEffect(() => {
    if (!creatingInventoryItem && (createdInventoryItem || failedCreatingInventoryItem)) {
      handleResponse({
        error: failedCreatingInventoryItem,
        data: createdInventoryItem,
        callback: () => afterChangeCallback(),
        successToastDetail: { label: 'messages.success', context: 'createInventoryItem' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'createInventoryItem' }
      })
    }
  }, [ creatingInventoryItem, createdInventoryItem, failedCreatingInventoryItem ])

  const [
    addInventoryItem,
    {
      error: failedAddingInventoryItem,
      data: addInventoryItemResponse = {},
      loading: addingInventoryItem
    }
  ] = useMutation(ADD_INVENTORY_ITEM)

  const addedInventoryItem = addInventoryItemResponse?.addInventoryItem
  useEffect(() => {
    if (!addingInventoryItem && (addedInventoryItem || failedAddingInventoryItem)) {
      handleResponse({
        error: failedAddingInventoryItem,
        data: addedInventoryItem,
        callback: () => afterChangeCallback(),
        successToastDetail: { label: 'messages.success', context: 'addInventoryItem' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'addInventoryItem' }
      })
    }
  }, [ addingInventoryItem, addedInventoryItem, failedAddingInventoryItem ])

  const afterChangeCallback = () => {
    refetchInventory()
    refetchInventoryItems()
  }

  const handleCreateSubItem = (data: any) => {
    const variables = {
      productID,
      productAmount: `${amount}`,
      name: data.name,
      value: '1',
      unit: 'Unit',
      isWaste: false
    }
    createInventoryItem({ variables })
  }

  const handleAddSubItem = (event: any) => {
    event.originalEvent.stopPropagation()
    const { value: inventoryItem } = event.item || {}
    if (!inventoryItem?.id) return

    const variables = {
      productID,
      productAmount: `${amount}`,
      inventoryItemID: inventoryItem?.id,
      inventoryItemAmount: '1'
    }
    addInventoryItem({ variables })
  }

  return (
    <ItemComponent {...{
      creatingInventoryItem,
      addingInventoryItem,
      handleAddSubItem,
      handleCreateSubItem,
      existingProducts,
      onEscape,
      node
    }}
    />
  )
}

