import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'

import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { AddNodeWrapperLayout } from '../../layout/add-node-wrapper.layout'
import { TAnalysisContext } from '../../../analysis/interface/analysis.context'
import AnalysisContext from '../../../analysis/provider/context/analysis.context'
import { AnalysisMode } from '../../../analysis/enum/analysis'
import { AddSubItemFormComponent, TAddSubItemFormComponentProps } from './tree-add-subitem-form.component'
import FeatureContext from '../../../dashboard/context/feature.context'
import { Feature } from '../../../shared/enum/feature'

export const AddSubItemNodeComponent = ({
  node = {},
  existingProducts = [],
  handleAddSubItem,
  handleCreateSubItem,
  creatingInventoryItem = false,
  addingInventoryItem = false,
}: TAddSubItemFormComponentProps) => {
  const { t } = useTranslation([ 'product' ])
  const {
    addInventoryItem,
    inventoryItems = []
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const [ createMode, setCreateMode ] = useState<boolean>(false)
  const { isFeatureEnabled } = useContext(FeatureContext)
  const enableProductDetailPanel = isFeatureEnabled(Feature.ProductDetailPanel) || false
  const { analysisMode, updateAnalysis } = useContext<TAnalysisContext>(AnalysisContext)
  useEffect(() => {
    if (!creatingInventoryItem && !addingInventoryItem) setCreateMode(false)
  }, [ creatingInventoryItem, addingInventoryItem ])
  const hasChildren = inventoryItems.length > 0

  const handleLegacyAddSubItem = (event: any) => {
    event.stopPropagation()
    if (analysisMode === AnalysisMode.Scenario) {
      updateAnalysis({ scenarioSelectedKey: 'root' })
    }
    addInventoryItem()
  }

  const handleInlineAddSubItem = () => {
    setCreateMode(true)
  }

  const onEscape = () => {
    setCreateMode(false)
  }

  return (
    <>
      {createMode && (
        <div className="flex align-items-center p-2 bg-primary-50 w-full border-round-lg">
          <AddSubItemFormComponent
            {...{
              creatingInventoryItem,
              addingInventoryItem,
              handleAddSubItem,
              handleCreateSubItem,
              existingProducts,
              onEscape,
              node
            }}
          />
        </div>
      )}
      {!createMode && (
        <AddNodeWrapperLayout onClick={!enableProductDetailPanel ? handleLegacyAddSubItem : handleInlineAddSubItem} dataCy="add-item-from-button" dataTestId="add-item-node-link">
          <div className="flex-none flex align-items-center justify-content-center">
            <FontAwesomeIcon data-testid="add-item-node-icon" icon={faPlus} className="text-base" />
          </div>
          <div data-testid="add-item-node-text" className="flex-grow-1 flex align-items-center text-base justify-content-start line-height-4">
            { !hasChildren ? t('actions.add', { context: 'item' }) : t('actions.add', { context: 'anotherItem' })}
          </div>
        </AddNodeWrapperLayout>
      )}
    </>
  )
}

