import React, {
  useContext, useEffect
} from 'react'
import { useTranslation } from 'react-i18next'
import { createGlobalStyle } from 'styled-components'

import { TImpactDatasetContext, TPageData } from '../../interface/impact-dataset.context'
import ImpactDatasetContext from '../../provider/context/impact-dataset.context'
import {
  FacetValue,
  FilterDatasetType, FilterElementaryFlowProperty, FilterElementaryFlowType
} from '../../../../__generated__/graphql'
import { ImpactDatasetViewType } from '../../enum/impact-dataset-view-type'
import { DatabaseCategoryGroupComponent } from './database-category-group.component'
import { ELEMENTARY_FLOW_ROOT } from '../../constant/categories'
import { DATABASE_GROUPS } from '../../constant/database-groups'

const GlobalStyle = createGlobalStyle`
  .elementary-flow-menu {
    .p-menuitem:not(:last-child) > div {
      padding-bottom: 1rem !important;
      margin-bottom: 0.5rem;
    }
  }
`

export const DatabaseCategoryFilterComponent = () => {
  const {
    selectedDatasetViewType,
    updateFilterValue,
    getDatasetPageData = () => ({} as TPageData),
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const {
    databaseCategoryList = [],
    selectedDatabases = [],
    selectedDatabaseCategories = [],
    selectedElementaryFlowCategories = []

  } = getDatasetPageData()
  const { t } = useTranslation('impact-dataset')

  const isSelectedViewType = (datasetViewType: ImpactDatasetViewType) => datasetViewType === selectedDatasetViewType

  useEffect(() => {
    if (isSelectedViewType(ImpactDatasetViewType.ElementaryFlow) && selectedElementaryFlowCategories.length === 0) {
      const preSelectedCategories:FilterElementaryFlowProperty[] = []
      const elementaryFlowFilter:FilterElementaryFlowProperty = {
        name: FilterElementaryFlowType.D1,
        values: [ ELEMENTARY_FLOW_ROOT ]
      }

      preSelectedCategories.push(elementaryFlowFilter)
      updateFilterValue({
        selectedElementaryFlowCategories: preSelectedCategories
      })
    }
  }, [ selectedDatasetViewType, selectedElementaryFlowCategories ])

  useEffect(() => {
    if (isSelectedViewType(ImpactDatasetViewType.DatabaseSearch) && databaseCategoryList.length > 0 && selectedDatabaseCategories.length === 0) {
      const databaseCategoriesD1Values: FacetValue[] = databaseCategoryList[0]?.values || [];
      const selectedGroups = selectedDatabases.flatMap(database => DATABASE_GROUPS.filter(group => database.includes(group)));
      const preSelectedGroups: FacetValue[] = databaseCategoriesD1Values.filter(value => selectedGroups.some(group => value.name.includes(group)));

      if (preSelectedGroups.length === 1) {
        updateFilterValue({
          selectedDatabaseCategories: [
            {
              name: FilterDatasetType.D1,
              values: [ preSelectedGroups[0].name ],
            },
            ...selectedDatabaseCategories
          ],
        });
      }
    }
  }, [ selectedDatasetViewType, databaseCategoryList, selectedDatabases ])

  const onDatabaseCategoriesChange = (categoryName: FilterDatasetType, updateValues: string[]) => {
    const updatedSelectedDatabaseCategories = [
      ...selectedDatabaseCategories.filter(category => category.name !== categoryName),
      {
        name: categoryName,
        values: updateValues
      }
    ]

    updateFilterValue({
      selectedDatabaseCategories: updatedSelectedDatabaseCategories
    })
  }

  const onElementaryFlowCategoriesChange = (categoryName: FilterElementaryFlowType, updateValues: string[]) => {
    const updatedSelectedElementaryFlowCategories = [
      ...selectedElementaryFlowCategories.filter(category => category.name !== categoryName),
      {
        name: categoryName,
        values: updateValues
      }
    ]

    updateFilterValue({
      selectedElementaryFlowCategories: updatedSelectedElementaryFlowCategories
    })
  }

  const parseCategoryTitle = (title:string = '') => {
    const parts = title.split(':');
    return parts.length > 1 ? `${parts[0]}: ${parts.slice(1).join(':')}` : title;
  }

  const databaseCategoriesToShow = databaseCategoryList.slice(0, selectedDatabaseCategories.length + 1);
  const elementaryFlowCategoriesToShow = databaseCategoryList.slice(0, selectedElementaryFlowCategories.length + 1);

  return (
    <>
      <GlobalStyle />
      { isSelectedViewType(ImpactDatasetViewType.DatabaseSearch) && databaseCategoriesToShow?.map((category, index) => {
        const selectedCategoryInfo = selectedDatabaseCategories.find(selectedCategory => selectedCategory.name === category.name) || { values: [] }
        const title = index === 0 ? t('labels.databaseCategories') : parseCategoryTitle(selectedDatabaseCategories[index - 1]?.values[0])

        return (
          <div className="block w-full" key={`category-wrapper-${index}`}>
            <DatabaseCategoryGroupComponent
              key={category.name}
              category={category}
              title={title}
              onDatabaseCategoriesChange={onDatabaseCategoriesChange}
              selectedValues={selectedCategoryInfo.values}
            />
          </div>
        )
      })}
      { isSelectedViewType(ImpactDatasetViewType.ElementaryFlow) && elementaryFlowCategoriesToShow?.map((category, index) => {
        const selectedCategoryInfo = selectedElementaryFlowCategories.find(selectedCategory => selectedCategory.name === category.name) || { values: [] }
        const title = index === 0 ? t('labels.databaseCategories') : parseCategoryTitle(selectedElementaryFlowCategories[index - 1]?.values[0])
        return index > 0 && (
          <div className="block w-full" key={`category-wrapper-${index}`}>
            <DatabaseCategoryGroupComponent
              key={category.name}
              category={category}
              title={title}
              onElementaryFlowCategoriesChange={onElementaryFlowCategoriesChange}
              selectedValues={selectedCategoryInfo.values}
            />
          </div>
        )
      })}
    </>
  )
}
