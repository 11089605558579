import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { TImpactDatasetContext, TPageData } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { EDatabaseSearchColumn } from '../enum/impact-dataset-column'

import { IDatabaseSearchResult } from '../interface/impact-dataset-result'
import { transformToDatabaseSearchResult } from '../util/transform'
import { TableWrapperComponent } from '../../shared/component/general/table-wrapper.component'
import { TableBodyTemplateComponent } from './table/table-body-template.component'
import { IProduct, IReferenceProduct } from '../../model'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { TableHeaderComponent } from './table/table-header-template.component'
import { ReferenceProductType } from '../enum/reference-product'
import { DatasetSortField, SortingOrder } from '../../../__generated__/graphql'

const TableWrapper = styled.div`
  .p-datatable {
    min-width: 50rem;

    &.with-adjustment {
      .p-datatable-thead:after {
        content: '';
        position: absolute;
        top: -0.5rem;
        left: 0;
        right: 0;
        height: 1rem;
        background-color: var(--surface-a);
      }
    }

    .p-datatable-wrapper {
      margin: 0 -1.5rem 0 -1.5rem;
      padding: 0 1.5rem 0 1.5rem;
    }

    .p-datatable-thead {
      display: table-header-group;
      background-color: var(--surface-a)!important;
      top: 0.5rem !important;

      tr {
        display: table-row;
      }

      th {
        display: table-cell;
      }
    }

    .p-datatable-tbody {
      display: table-row-group;

      tr {
        display: table-row;
        border-radius: 0.5rem;
        box-shadow: 0 5px 25px 5px rgba(0, 0, 0, 0.05);

        &:hover {
          box-shadow: 0 5px 25px 5px rgba(0, 0, 0, 0.25);
          background-color: var(--primary-50);
        }

        td {
          display: table-cell;
          cursor: auto;
        }
      }
    }

    .p-datatable-emptymessage {
      display:none !important;
    }
  }
`
type TDatabaseSearchComponentProp = {
  tableRef?: React.RefObject<DataTable>
  showResults: boolean
}
export const DatabaseSearchComponent = ({ showResults, tableRef }: TDatabaseSearchComponentProp) => {
  const { t } = useTranslation([ 'impact-dataset', 'common' ])
  const {
    referenceProducts = [],
    comparisonDatabaseSearchItems = [],
    enableDatabaseSearchComparison,
    updateFilterValue,
    updateImpactDataset,
    getDatasetPageData = () => ({} as TPageData)
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const { selectedSortOrder, selectedSortBy } = getDatasetPageData()
  const [ mouseOverOn, setMouseOverOn ] = useState<string | null>(null)
  const { selectedEntity = {}, notifyToastPrime } = useContext<IDashboardContext>(DashboardContext)

  const { referenceProduct: currentReferenceProduct } = selectedEntity as IProduct || {}

  let dataToTransform = referenceProducts
  if (referenceProducts.length > 0 && currentReferenceProduct?.id) {
    dataToTransform = referenceProducts.filter(referenceProduct => referenceProduct.id !== currentReferenceProduct?.id)
  }
  const impactDatasetResults = transformToDatabaseSearchResult(dataToTransform)

  const handleSortTable = (sortBy: DatasetSortField, sortOrderTo: SortingOrder) => {
    if (tableRef?.current) {
      tableRef.current.getElement().children[0].scrollTop = 0
    }
    if (selectedSortBy === sortBy && selectedSortOrder === SortingOrder.Desc) {
      updateFilterValue({ selectedSortBy: undefined, selectedSortOrder: undefined })
      return
    }

    updateFilterValue({ selectedSortBy: sortBy, selectedSortOrder: sortOrderTo })
  }

  const headerTemplate = ({
    label,
    column,
    sortBy = DatasetSortField.Name,
    sortDisabled = true
  } : {
    label: string,
    column?: EDatabaseSearchColumn,
    sortBy?: DatasetSortField,
    sortDisabled?: boolean
  }) => (
    <TableHeaderComponent
      {...{
        selectedSortOrder, sortDisabled, label, column, sortBy, selectedSortBy, onSortClick: handleSortTable
      }}
    />
  )

  const onRowMouseEnter = (id: string) => {
    setMouseOverOn(id)
  }

  const onRowMouseLeave = () => {
    setMouseOverOn(null)
  }

  const getSelectedRowIndex = (rowData: IReferenceProduct) => comparisonDatabaseSearchItems.findIndex((item: IReferenceProduct) => item.id === rowData.id)

  const getReferenceProduct = (id: string) => {
    if (currentReferenceProduct?.id === id) {
      return currentReferenceProduct
    }

    return referenceProducts.find((item: IReferenceProduct) => item.id === id)
  }

  const onRowSelection = (e: any) => {
    const rowData = e.value as IDatabaseSearchResult
    const selectedIndex = getSelectedRowIndex(rowData)

    if (selectedIndex < 0 && comparisonDatabaseSearchItems.length >= 3) {
      notifyToastPrime?.show({
        severity: 'warn',
        detail: t('messages.maxComparisonLimitDetail', { context: 'warn' }),
        summary: t('messages.maxComparisonLimitSummary', { context: 'warn' }),
        life: 3000
      })
      return
    }

    const updatedComparisonItems = [ ...comparisonDatabaseSearchItems ]
    if (selectedIndex < 0) {
      const selectedReferenceProduct = getReferenceProduct(rowData.id)
      selectedReferenceProduct && updatedComparisonItems.push(selectedReferenceProduct)
    } else {
      updatedComparisonItems.splice(selectedIndex, 1)
    }
    updateImpactDataset({ comparisonDatabaseSearchItems: updatedComparisonItems })
  }

  const bodyTemplate = (rowData: IDatabaseSearchResult, column: EDatabaseSearchColumn) => (
    <TableBodyTemplateComponent
      value={rowData}
      column={column}
      onRowMouseEnter={onRowMouseEnter}
      onRowMouseLeave={onRowMouseLeave}
      onRowSelection={onRowSelection}
      isMouseOver={rowData.id === mouseOverOn}
      isSelected={getSelectedRowIndex(rowData) >= 0}
    />
  )

  const setRowColorForSelected = (impactDataset: IDatabaseSearchResult) => {
    const isCurrentActiveReference = currentReferenceProduct?.id === impactDataset.id
    const isSelectedReference = getSelectedRowIndex(impactDataset) >= 0
    return { 'bg-primary-50': isCurrentActiveReference || isSelectedReference }
  }

  const thereAreSomeResults = impactDatasetResults.length > 0
  const currentReferenceProductIsDataset = currentReferenceProduct?.type === ReferenceProductType.ProductSystem
  const showActiveDataset = currentReferenceProductIsDataset && thereAreSomeResults
  const tableFrozenValues = showActiveDataset ? transformToDatabaseSearchResult([ currentReferenceProduct ]) : []

  return (
    <TableWrapper
      className="flex w-full"
      data-testid="datasets-search-results-table"
    >
      <TableWrapperComponent>
        <DataTable
          ref={tableRef}
          value={showResults ? impactDatasetResults : []}
          frozenValue={tableFrozenValues}
          scrollable={thereAreSomeResults}
          scrollHeight="flex"
          rowClassName={setRowColorForSelected}
          className={thereAreSomeResults ? 'with-adjustment' : ''}
        >
          {
            enableDatabaseSearchComparison && (
              <Column
                field={EDatabaseSearchColumn.Selection}
                className="border-round-left-lg"
                style={{ width: '3rem' }}
                body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.Selection)}
                header={() => <></>}
              />
            )
          }
          <Column
            field={EDatabaseSearchColumn.DatasetName}
            className={enableDatabaseSearchComparison ? 'border-noround' : 'border-round-left-lg'}
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.DatasetName)}
            header={headerTemplate({ label: t('labels.datasetName'), column: EDatabaseSearchColumn.DatasetName, sortDisabled: false })}
          />

          <Column
            field={EDatabaseSearchColumn.Geography}
            style={{ width: '9rem' }}
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.Geography)}
            header={headerTemplate({ label: t('labels.geography'), column: EDatabaseSearchColumn.Geography, sortDisabled: true })}
          />

          <Column
            field={EDatabaseSearchColumn.Property}
            style={{ width: '9rem' }}
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.Property)}
            header={headerTemplate({ label: t('labels.property', { ns: 'common' }), column: EDatabaseSearchColumn.Property, sortDisabled: true })}
          />

          <Column
            field={EDatabaseSearchColumn.Database}
            style={{ width: '9rem' }}
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.Database)}
            header={headerTemplate({ label: t('labels.database'), column: EDatabaseSearchColumn.Database, sortDisabled: true })}
          />

          <Column
            field={EDatabaseSearchColumn.Action}
            className="border-round-right-lg"
            style={{ width: '10rem' }}
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.Action)}
            header={() => <></>}
          />
        </DataTable>
      </TableWrapperComponent>
    </TableWrapper>
  )
}
