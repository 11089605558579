import React, {
  useContext, useEffect, useRef
} from 'react'
import {
  useSearchParams
} from 'react-router-dom'
import { useMutation, useReactiveVar } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'

import { ADD_PRODUCT_PROPERTY, CHANGE_PRODUCT_REFERENCE } from '../../graphql/mutation'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { displayGraphqlErrors } from '../../shared/util/error'
import { IProduct, IReferenceProduct } from '../../model'
import { useDatasetNavigate } from '../hook/use-dataset-navigate'
import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import { useScenarioAction } from '../../analysis/hook/use-scenario-action'
import { ScenarioAction } from '../../analysis/enum/scenario-action'
import { ErrorCode } from '../../shared/enum/error'
import { selectedAccountVar } from '../../../graphql/cache'

type AddImpactDatasetContainerProps = {
  referenceProductID?: string,
  isMouseOver?: boolean,
  isFromDatasetDetail?: boolean
}
export const AddImpactDatasetContainer = ({
  referenceProductID,
  isMouseOver = false,
  isFromDatasetDetail = false
}: AddImpactDatasetContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const toast = useRef<Toast>(null)
  const { selectedEntity, updateDashboard } = useContext<IDashboardContext>(DashboardContext)
  const {
    selectedDatasetViewType,
    referenceProducts = []
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const { account: { isTrialing = false, } = {} } = useReactiveVar(selectedAccountVar) || {}

  const [ searchParams ] = useSearchParams()
  const scenarioProductID = searchParams.get('scenarioProductID')
  const scenarioSelectedKey = searchParams.get('scenarioSelectedKey')
  const handleScenarioAction = useScenarioAction(scenarioProductID)
  const [
    changeProductReference,
    {
      error: failedChangingReferenceProduct,
      data: changeProductReferenceData,
      loading: changingReferenceProduct
    }
  ] = useMutation(CHANGE_PRODUCT_REFERENCE)
  const [
    addProductProperty,
    {
      error: failedAddingProductProperty,
      data: addProductPropertyData,
      loading: addingProductProperty
    }
  ] = useMutation(ADD_PRODUCT_PROPERTY)

  const { navigateBackToSource } = useDatasetNavigate()

  const addedProductProperty = addProductPropertyData?.addProductProperty
  useEffect(() => {
    if (!addingProductProperty && (!!addedProductProperty || failedAddingProductProperty)) {
      addProductPropertyCallback()
    }
  }, [ addingProductProperty, !!addedProductProperty, failedAddingProductProperty ])

  const changedReferenceProduct = changeProductReferenceData?.changeProductReference

  useEffect(() => {
    if (!changingReferenceProduct && (!!changedReferenceProduct || failedChangingReferenceProduct)) {
      changeImpactProductCallback(failedChangingReferenceProduct, changeProductReferenceData)
    }
  }, [ changingReferenceProduct, !!changedReferenceProduct, failedChangingReferenceProduct ])
  const [ trackEventInSegment ] = getSegmentTrack()
  const isDatabaseSearch = selectedDatasetViewType === ImpactDatasetViewType.DatabaseSearch

  const performChangeReference = () => {
    if (!selectedEntity?.id || !referenceProductID) return
    const variables = { productID: selectedEntity.id, referenceProductID }

    if (handleScenarioAction && scenarioSelectedKey) {
      handleScenarioAction(ScenarioAction.ChangeProductReference, variables, scenarioSelectedKey, changeImpactProductCallback)
    } else {
      changeProductReference({ variables })
    }
  }

  const handleChangeReferenceProduct = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    if (!selectedEntity?.id || !referenceProductID || !('productProperties' in selectedEntity)) return

    const selectedReferenceProduct = referenceProducts.find((referenceProduct:IReferenceProduct) => referenceProduct.id === referenceProductID)
    const hasReferenceProperty = selectedEntity?.productProperties?.some(({ referenceProperty = {} }) => referenceProperty.id === selectedReferenceProduct?.referenceProperty?.id)

    if (hasReferenceProperty) {
      performChangeReference()
      return
    }

    if (selectedReferenceProduct?.referenceProperty?.id && selectedReferenceProduct?.referenceUnit?.id) {
      addProductProperty({
        variables: {
          productID: selectedEntity?.id,
          referencePropertyID: selectedReferenceProduct?.referenceProperty?.id,
          conversionFactor: String(parseFloat(selectedReferenceProduct?.amount || '')),
          referenceUnitID: selectedReferenceProduct?.referenceUnit?.id
        }
      })
    }
  }

  const addProductPropertyCallback = () => {
    try {
      if (failedAddingProductProperty) {
        throw failedAddingProductProperty
      } else if (addedProductProperty && selectedEntity?.id && referenceProductID) {
        performChangeReference()
        updateDashboard({ showUpdatePropertyDialog: true })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.errorSummary'), error?.graphQLErrors, t)
    }
  }

  const changeImpactProductCallback = (error?: any, data?: any) => {
    try {
      if (error) {
        throw error
      } else if (data) {
        trackEventInSegment(isDatabaseSearch ? TrackEventType.ADDED_DATASET : TrackEventType.ADDED_ELEMENTARY_FLOW)
        let selectedEntity = null
        if (scenarioSelectedKey && data.performScenarioAction) {
          selectedEntity = data.performScenarioAction
        } else if (data.changeProductReference) {
          selectedEntity = data.changeProductReference
        }
        selectedEntity && updateDashboard({ selectedEntity })
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.success', { context: 'changeImpactProduct', ns: 'product' }),
          life: 1000
        })
      }
    } catch (error: any) {
      const errorCode = error?.graphQLErrors?.[0]?.extensions?.code
      if (errorCode === ErrorCode.DATASET_USAGE_LIMIT_REACHED && isTrialing) {
        updateDashboard({ showDatasetLimitDialog: true })
        return
      }

      displayGraphqlErrors(toast, t('messages.errorSummary'), error?.graphQLErrors, t)
    }
  }

  const isAdded = () => {
    const entity = selectedEntity as IProduct
    return entity?.referenceProduct?.id === referenceProductID
  }

  return (
    <div className="flex align-items-center w-full h-full" data-id={referenceProductID}>

      <Toast data-testid="add-impact-status" ref={toast} position="top-right" onHide={navigateBackToSource} />

      <div className="flex w-full">
        { !isAdded() && (isMouseOver || isFromDatasetDetail)
          && (
            <Button
              className={
                classNames('p-button-sm h-2rem justify-content-center', {
                  'w-full': isFromDatasetDetail
                })
              }
              onClick={handleChangeReferenceProduct}
              loading={addingProductProperty || changingReferenceProduct}
              data-cy="addDatasetButton"
            >
              { isFromDatasetDetail && <FontAwesomeIcon icon={faPlus} className="text-basis text-white mr-1" /> }
              { t('labels.add') }
            </Button>
          )}
        { isAdded()
          && (
            <Button
              className={
                classNames('p-button-sm border-gray-200 h-2rem bg-gray-50 text-gray-200 justify-content-center', {
                  'w-full': isFromDatasetDetail
                })
              }
              disabled
              data-cy="addedDatasetButton"
            >
              { isFromDatasetDetail && <FontAwesomeIcon icon={faPlus} className="text-basis text-gray-200 mr-1" /> }
              { t('labels.added') }
            </Button>
          )}
      </div>
    </div>
  )
}
