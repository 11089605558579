import React, {
  useState, useRef
} from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPage } from '@fortawesome/pro-regular-svg-icons'

import styled from 'styled-components';

import { Menu } from 'primereact/menu';
import { useForm, Controller } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { IControllerRender } from '../../../shared/interface/react-form-hook'
import { ProgressSpinnerComponent } from '../../../shared/component/general/progress-spinner.component';
import { ProductLabel } from '../../../shared/enum';

const SubItemFormWrapper = styled.div`
  .product-menu {
    display: fixed;
    width: 15.5rem;
    max-height: 10rem;
    overflow: auto;
    top: unset !important;
    left: 2.25rem !important;

    &__aboveInput {
      bottom: 1.75rem !important;
    }

    &__belowInput {
      top: 1.75rem !important;
    }
  }

  .p-submenu-header {
    font-size: 16px;
    font-weight: 500;
    color: var(--gray-500);
    padding: 0.25rem 1rem;
  }

  input {
    width: 15.5rem;
    padding: 0 0.5rem;
    height: 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid var(--primary-500);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

`

export type TAddSubItemFormComponentProps = {
  node?: any,
  existingProducts?: any[],
  onEscape?: (event: any) => void,
  handleCreateSubItem?: (data: any) => void,
  handleAddSubItem?: (data: any) => void,
  creatingInventoryItem?: boolean,
  addingInventoryItem?: boolean,
}
export const AddSubItemFormComponent = ({
  node = {},
  existingProducts = [],
  onEscape = () => {},
  handleAddSubItem = () => {},
  handleCreateSubItem = () => {},
  creatingInventoryItem = false,
  addingInventoryItem = false,
}: TAddSubItemFormComponentProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const [ menuIsPlacedAboveInput, setIsMenuPlacedAboveInput ] = useState<boolean>(false)
  const [ productMenuItems, setProductMenuItems ] = useState<{ label?: string, value?: any }[]>([])

  const formRef = useRef<HTMLFormElement>(null)
  const menuRef = useRef<Menu>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const defaultValues = { name: '' }
  const {
    control, handleSubmit, formState: { errors = {} }
  } = useForm({ defaultValues })

  const onKeyUp = (e: any) => {
    if (e.key === 'Escape') onEscape(node)
  }

  const getProductMenuItems = (filterBy?: string): { label?: string, items?: any[] }[] => {
    const allElements = filterBy
      ? existingProducts.filter(existingProduct => existingProduct.name?.toLowerCase().includes(filterBy.toLowerCase()))
      : existingProducts

    if (allElements.length === 0) return ([ { label: 'No item found' } ])

    const menuItems = [];
    const products = allElements.filter(product => product.labels[0].id.toLowerCase() === ProductLabel.Product.toLowerCase())
    const objects = allElements.filter(object => object.labels[0].id.toLowerCase() === ProductLabel.Object.toLowerCase())

    if (products.length > 0) {
      menuItems.push({ label: 'Products', items: products.map(product => ({ label: product.name, value: product, command: handleAddSubItem })) })
    }
    if (objects.length > 0) {
      menuItems.push({ label: 'Objects', items: objects.map(object => ({ label: object.name, value: object, command: handleAddSubItem })) })
    }

    return menuItems
  }

  const stopPropagation = (event: any) => event.stopPropagation()

  const setProductMenuItemPosition = (event: any) => {
    stopPropagation(event)
    if (!wrapperRef?.current) return

    const { offsetParent, offsetTop } = wrapperRef.current || {}
    const { top: wrapperTop } = wrapperRef.current.getBoundingClientRect() || {}

    const top = wrapperTop || offsetTop
    const { offsetHeight: offsetParentHeight } = offsetParent as any || {}

    if (!offsetParentHeight || !top) return

    setIsMenuPlacedAboveInput((offsetParentHeight - top - 320 <= 0))
  }

  const onBlur = () => {
    if (creatingInventoryItem || addingInventoryItem) return

    onEscape(node)
  }

  return (
    <SubItemFormWrapper
      ref={wrapperRef}
      onClick={stopPropagation}
      data-cy="add-subitem-form"
      data-testid="add-subitem-form"
      className="flex gap-2 justify-content-end align-items-center relative"
    >
      <Menu
        popup
        ref={menuRef}
        id="popup_menu"
        model={productMenuItems}
        onClick={stopPropagation}
        onShow={setProductMenuItemPosition}
        className={classNames('product-menu z-2', {
          'product-menu__aboveInput': menuIsPlacedAboveInput,
          'product-menu__belowInput': !menuIsPlacedAboveInput
        })}
        appendTo="self"
      />
      <div className="flex-none flex align-items-center justify-content-center pl-2 line-height">
        <div className="ml-2 tree-item-file-icon">
          <FontAwesomeIcon data-tree-item data-testid="tree-item-file-icon" icon={faPage} className="text-base" />
        </div>
      </div>
      <div className="flex-grow-1 align-items-center">
        {
          (creatingInventoryItem || addingInventoryItem)
            ? (
              <div data-testid="progress-spinner" className="flex px-3">
                <ProgressSpinnerComponent size={1} />
              </div>
            )
            : (
              <form ref={formRef} onSubmit={handleSubmit(handleCreateSubItem)}>
                <div className="flex flex-column">
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: t('messages.errorMessage', { context: 'itemName' }) || '' }}
                    render={({ field, fieldState }: IControllerRender) => (
                      <InputText
                        id="name"
                        {...field}
                        autoFocus
                        autoComplete="off"
                        placeholder={t('labels.create', { context: 'newItemOrUseExisting' }) || ''}
                        onClick={(event: any) => {
                          event.stopPropagation()
                          menuRef.current?.show(event)
                          setProductMenuItems(getProductMenuItems())
                        }}
                        onChange={(event: any) => {
                          field.onChange(event)
                          menuRef.current?.show(event)
                          setProductMenuItems(getProductMenuItems(event.target?.value))
                        }}
                        onKeyUp={onKeyUp}
                        onBlur={onBlur}
                        className={classNames('text-sm', { 'p-invalid': fieldState.error })}
                        tooltip={t('actions.saveOrCancel', { ns: 'common' }) || ''}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    )}
                  />
                  { errors?.name && <small className="ml-2 p-error">{ errors?.name?.message }</small> }
                </div>
              </form>
            )
        }
      </div>
    </SubItemFormWrapper>
  )
}

