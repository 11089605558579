import React, { useContext, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import styled, { keyframes } from 'styled-components'
import { classNames } from 'primereact/utils'
import { Tag } from 'primereact/tag'

import { LogoIcon } from '../../../component/shared/customIcons'
import { AccountNavContainer } from '../container/account-nav.container'
import { WorkspaceNavContainer } from '../container/workspace-nav.container'
import DashboardContext from '../context/dashboard.context'
import { TalkExpertComponent } from '../component/talk-expert'
import { TrialCounterComponent } from '../component/trial-counter'
import { getEnvFromUrl } from '../../shared/util/navigation'
import { Environment } from '../../shared/enum'
import { PageNavigationComponent } from '../component/page-navigation'
import { SidebarComponent } from '../component/sidebar.component'
import { useWindowSize } from '../../shared/hook/use-window-size'
import { ScreenSizes } from '../../shared/enum/screen'
import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'
import { selectedAccountVar } from '../../../graphql/cache'
import FeatureContext from '../context/feature.context'
import { Feature } from '../../shared/enum/feature'

const slideOpen = {
  left: keyframes`
    from { left: 3.8rem; }
    to { left: 14.25rem; }
  `,
  width: keyframes`
    from { width: 4.5rem; }
    to { width: 15rem; }
  `
}

const slideClose = {
  left: keyframes`
    from { left: 14.25rem; }
    to { left: 3.8rem; }
  `,
  width: keyframes`
    from { width: 15rem; }
    to { width: 4.5rem; }
  `
}

interface NavigationProps {
  readonly collapsedNavigation?: boolean;
}

const Navigation = styled.div<NavigationProps>`
  animation: ${(props: NavigationProps) => (props?.collapsedNavigation ? slideClose.width : slideOpen.width)} 0.25s ease-in-out 0s forwards;
  background-color: var(--primarydark-500);

  .scrollable-section {
    overflow-y: overlay;
    overflow-x: hidden;
  }

  .scrollable-section::-webkit-scrollbar {
    width: 0.313rem;
  }

  .scrollable-section::-webkit-scrollbar-track {
    background: var(--surface-50);
    border-radius: 0.125rem;
  }

  .scrollable-section::-webkit-scrollbar-thumb {
    background-color: var(--surface-100);
    border-radius: 0.125rem;
  }
`

const Header = styled.div<NavigationProps>`

  svg {
    height: ${(props: NavigationProps) => (props?.collapsedNavigation ? '1.3rem' : '1.5rem')};
    fill: var(--green-500);
  }

  h1 {
    font-size: 24px;
    line-height: 1.5rem;
    color: var(--bluegray-50);
    font-family: "Rubik", sans-serif;
    margin: 0;
    padding: 0;
  }
`

const Closer = styled.div<NavigationProps>`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.36rem;

  animation: ${(props: NavigationProps) => (props?.collapsedNavigation ? slideClose.left : slideOpen.left)} 0.25s ease-in-out 0s forwards;
  top: 4.5rem;

  -webkit-box-shadow: 0 0 0.313rem 0 var(--bluegray-800);
  -moz-box-shadow: 0 0 0.313rem 0 var(--bluegray-800);
  box-shadow: 0 0 0.313rem 0 var(--bluegray-800);
`

const CurrentEnvironment = styled.div`
  width: fit-content;
  font-size: 0.75rem;
`

export const NavigationLayout = () => {
  const environment = getEnvFromUrl()
  const { collapsedNavigation, updateDashboard } = useContext(DashboardContext)
  const { isFeatureEnabled } = useContext(FeatureContext)
  const enableTreeDnDPolarChartFixedSidebar = isFeatureEnabled(Feature.TreeDnDPolarChartFixedSidebar) || false
  const selectedAccount = useReactiveVar(selectedAccountVar) || {}
  const [ windowWidth ] = useWindowSize()
  const { account, emptyAccount } = selectedAccount

  useEffect(() => {
    if (windowWidth < ScreenSizes.lg) {
      updateDashboard({ collapsedNavigation: true })
    }
  }, [ windowWidth ])

  const toggleNavigation = () => {
    updateDashboard({ collapsedNavigation: !collapsedNavigation })
  }

  return (
    <Navigation
      collapsedNavigation={collapsedNavigation}
      data-testid="navigation-layout"
      className="flex flex-column h-full"
    >
      <Header
        collapsedNavigation={collapsedNavigation}
        className={classNames('flex-none flex w-full h-5rem p-4', { 'px-3': collapsedNavigation })}
      >
        <div className="flex-none py-2">
          <LogoIcon />
        </div>
        {!collapsedNavigation && (
          <div data-testid="text-logo" className="flex-grow-1 p-2">
            <h1>Mobius</h1>
          </div>
        )}
      </Header>

      <div className="flex-none w-full">
        { !account && !emptyAccount && (
          <div data-testid="account-container-loading" className="w-full px-3 py-4">
            <ProgressSpinnerComponent size={2} />
          </div>
        )}
        {(account || emptyAccount) && <WorkspaceNavContainer />}
      </div>

      <div className="flex flex-column flex-grow-1 w-full scrollable-section">
        <div className="flex-none w-full">
          <PageNavigationComponent />
        </div>
      </div>

      <div className="flex-none w-full px-3">
        { !collapsedNavigation && <TrialCounterComponent /> }
      </div>
      <div className="flex-none w-full mb-3">
        <AccountNavContainer />
      </div>

      <Closer
        onClick={toggleNavigation}
        data-testid="toggle-navigation"
        collapsedNavigation={collapsedNavigation}
        className={classNames('absolute bg-bluegray-50 text-xs border-round-xl z-1 cursor-pointer pi', {
          'pi-chevron-left': !collapsedNavigation,
          'pi-chevron-right': collapsedNavigation
        })}
      />

      {environment !== Environment.prd && (
        <CurrentEnvironment className="absolute p-1">
          <Tag
            value={environment}
            rounded
            className={classNames('text-white py-0 px-2', {
              'bg-green-700': environment === Environment.dev,
              'bg-yellow-500': environment === Environment.acc,
              'bg-purple-600': environment === Environment.tst,
              'bg-pink-500': environment === Environment.unknown
            })}
          />
        </CurrentEnvironment>
      )}

      <TalkExpertComponent />

      { !enableTreeDnDPolarChartFixedSidebar && <SidebarComponent /> }
    </Navigation>
  )
}
