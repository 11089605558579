import { Col, Row } from 'antd'
import React from 'react'
import classnames from 'classnames'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { isEmpty, returnNested, formatLifecycleName, normalize } from '../../../utils/tools'
import LoadingWrapper from '../../shared/loadingWrapper'
import InventoryTotalImpact from '../inventory/inventory-total-impact/inventoryTotalImpact.container'
import LifecycleTreeView from './lifecycleTreeView.container'
import InventoryNameLabel from '../inventory/inventory-name-label/inventoryNameLabel'

class LifecycleTreeWrapper extends React.PureComponent {

  onLifecycleNameClick = lifecycleId => {
    this.props.setLifecycleDetailsPanelSaga(lifecycleId)
  }

  render() {
    const {
      lifecycle,
      isLoadingLeftTree,
      t
    } = this.props

    const SingleTree = () => <Col className="single-tree" xl={24}>
      {lifecycle && <LoadingWrapper loading={ isLoadingLeftTree }>
        <LifecycleTreeView lifecycle={lifecycle} />
      </LoadingWrapper>
      }
    </Col>

    const SingleTreeTotal = () => <Col className="single-tree" data-cy="lifecycle-total-impact">
      <InventoryTotalImpact
        impact={ returnNested(lifecycle, 'inventory', 'totalImpact') }
        isCarbonTranslatorShowed={true}
      />
    </Col>

    return <>
      <Row gutter={16} type="flex" onClick={evt => evt.stopPropagation()}>
        <SingleTree />
      </Row>
      <Row gutter={16} type="flex">
        <SingleTreeTotal />
      </Row>
    </>
  }
}

export default compose(
  withTranslation()
)(LifecycleTreeWrapper)
