import React, { useContext } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/pro-regular-svg-icons'

import { FilterChipsComponent } from '../component/filter/filter-chips.component'
import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { setSelectedDatabasesStorageItem } from '../../shared/util/impact'
import { CommonCategoriesFilterComponent } from '../component/filter/common-categories-filter.component'
import { ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import { selectedWorkspaceVar } from '../../../graphql/cache'
import { DatabaseCategoryFilterComponent } from '../component/filter/database-category-filter.component'

const CategoryFilterWrapper = styled.div`
  height: calc(100% - 2rem);
  overflow: overlay;

  .p-button-label {
    font-size: .875rem;
  }
`

export const SidebarFilterLayout = () => {
  const { t } = useTranslation([ 'common' ])
  const {
    updateFilterValue,
    selectedDatasetViewType,
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const { space: selectedSpace } = useReactiveVar(selectedWorkspaceVar) || {}
  const { databases = [] } = selectedSpace || {}
  const defaultSelectedDatabasesNames = databases.map(database => database.name)

  const clearFilters = () => {
    setSelectedDatabasesStorageItem(defaultSelectedDatabasesNames)
    updateFilterValue({
      selectedCommonCategories: [],
      selectedDatabaseCategories: [],
      selectedElementaryFlowCategories: [],
      selectedDatabases: defaultSelectedDatabasesNames,
      selectedLocations: [],
      selectedLocationRegions: [],
      selectedProperties: [],
      selectedFlowProperties: []
    })
  }

  const showCommonCategories = selectedDatasetViewType !== ImpactDatasetViewType.ElementaryFlow

  return (
    <>
      <div className="flex w-full px-4">
        <div className="flex w-1rem h-full align-items-center text-gray-500">
          <FontAwesomeIcon data-testid="sidebar-filter-icon" icon={faFilter} className="text-base" />
        </div>
        <div className="flex flex-grow-1 align-items-center h-full pl-2 text-gray-700 font-medium text-base">
          { t('labels.filters') }
        </div>
        <div className="flex ">
          <span onClick={clearFilters} className="cursor-pointer font-medium align-content-end">{ t('labels.clearFilters') }</span>
        </div>
      </div>

      <CategoryFilterWrapper className="flex flex-column w-full px-4 pb-0">
        <div className="flex flex-column w-full pb-0">
          <FilterChipsComponent onClearFilters={clearFilters} />
        </div>

        {showCommonCategories && <CommonCategoriesFilterComponent />}
        <DatabaseCategoryFilterComponent />
      </CategoryFilterWrapper>
    </>
  )
}
