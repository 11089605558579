import React, {
  useContext, useRef, useEffect
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useReactiveVar } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import { useSearchParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'

import { ImpactDatasetContainer } from '../container/impact-dataset.container'
import { ReferenceProductType } from '../enum/reference-product'

import { DefaultLandingComponent } from '../component/default-landing.component'
import { DatabaseSearchComponent } from '../component/database-search.component'
import { PagePaginatorComponent } from '../../shared/component/general/page-paginator.component'
import { TImpactDatasetContext, TPageData } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'

import { ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import { CategoryType } from '../enum/category'

import { SearchFieldComponent } from '../component/search-field.component'
import { SearchFieldHelpComponent } from '../component/search-field-help.component'
import { ElementaryFlowComponent } from '../component/elementary-flow.component'
import { selectedWorkspaceVar } from '../../../graphql/cache'
import { PAGINATION } from '../../shared/constants'
import { SidebarFilterLayout } from './sidebar-filter.layout'
import { CompareDatasetsComponent } from '../component/compare-datasets.component'

interface ContentWrapperProps {
  readonly showSearchTips?: boolean
}

const SidebarWrapper = styled.div<ContentWrapperProps>`
  width: 20rem;
  box-shadow: ${(props: ContentWrapperProps) => (props.showSearchTips ? '-10px 0px 16px -12px rgba(0,0,0,0.2)' : '10px 0px 16px -12px rgba(0,0,0,0.2)')} ;
`

const ContentWrapper = styled.div<ContentWrapperProps>`
  width: ${(props: ContentWrapperProps) => (props.showSearchTips ? 'calc(100% - 41rem)' : 'calc(100% - 20rem)')};
`

const ResultsWrapper = styled.div<{ noResultsFound: boolean }>`
  height: ${props => props.noResultsFound ? 'auto' : 'calc(100vh - 20.75rem)'};
`

export const SearchDatasetLayout = () => {
  const {
    showSearchTips = false,
    updateFilterValue,
    referenceProducts = [],
    initResultsAreLoading,
    getDatasetPageData = () => ({} as TPageData),
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const [ searchParams ] = useSearchParams()
  const selectedDatasetViewType = searchParams.get('activeTab') as ImpactDatasetViewType
  const {
    query = '',
    currentPage = 1,
    totalItems = 0,
    selectedDatabaseCategories = [],
    selectedElementaryFlowCategories = [],
    selectedDatabases = [],
    selectedProperties = [],
    selectedLocations = [],
    selectedLocationRegions = [],
    selectedCommonCategories = []
  } = getDatasetPageData()
  const { t } = useTranslation([ 'common', 'impact-dataset' ])
  const firstProduct = (currentPage - 1) * PAGINATION.PAGE_SIZE
  const tableRef = useRef<DataTable>(null)
  const { space: selectedWorkspace } = useReactiveVar(selectedWorkspaceVar) || {}
  const { impactMethod } = selectedWorkspace || {}
  const noResultsFound = referenceProducts.length === 0

  const onCustomPageChange = (event: any) => {
    updateFilterValue({}, event.page + 1)
    if (tableRef.current) {
      tableRef.current.getElement().children[0].scrollTop = 0
    }
  }

  useEffect(() => {
    if (totalItems > 0 && tableRef.current) {
      tableRef.current.getElement().children[0].scrollTop = 0
    }
  }, [ totalItems ])

  const isSelectedViewType = (datasetViewType: ImpactDatasetViewType) => datasetViewType === selectedDatasetViewType

  const hasMore = () => totalItems > PAGINATION.PAGE_SIZE

  const hasActiveCommonCategory = selectedCommonCategories.some(category => category.values.length > 0)
  const hasActiveColumnFilter = selectedLocations.length > 0 || selectedLocationRegions.length > 0 || selectedDatabases.length > 0 || selectedProperties.length > 0
  const hasActiveDatabaseCategory = selectedDatabaseCategories.some(category => category.values.length > 0) || selectedElementaryFlowCategories.some(category => category.values.length > 0)

  const getProductAndCategoryTypes = (): [ReferenceProductType, CategoryType] => {
    if (isSelectedViewType(ImpactDatasetViewType.DatabaseSearch)) {
      return [ ReferenceProductType.ProductSystem, CategoryType.Process ]
    }

    return [ ReferenceProductType.ElementaryFlow, CategoryType.Flow ]
  }

  const [ productReferenceType ] = getProductAndCategoryTypes()
  const sideOrTopFilterEnabled = hasActiveColumnFilter || hasActiveCommonCategory || hasActiveDatabaseCategory
  const searchOrFilterEnabled = sideOrTopFilterEnabled || !!query

  const showDefaultLanding = !searchOrFilterEnabled && !initResultsAreLoading;
  const showNoResultsFound = searchOrFilterEnabled && noResultsFound && !initResultsAreLoading;
  const showPagination = hasMore() && searchOrFilterEnabled && !initResultsAreLoading;

  const getDisplayContent = () => (
    <>
      <div className="flex w-full pt-3 pl-3 text-sm font-medium">
        { t('labels.results', { number: searchOrFilterEnabled ? totalItems : 0, ns: 'impact-dataset' }) }
      </div>

      <ResultsWrapper className="flex w-full" noResultsFound={noResultsFound}>
        { isSelectedViewType(ImpactDatasetViewType.DatabaseSearch)
          ? <DatabaseSearchComponent showResults={searchOrFilterEnabled} tableRef={tableRef} />
          : <ElementaryFlowComponent showResults={searchOrFilterEnabled} tableRef={tableRef} /> }
      </ResultsWrapper>
      {(!searchOrFilterEnabled || noResultsFound) && <div className="border-bottom-1 border-gray-100" />}
      {showDefaultLanding && (
        <div className="flex flex-grow-1 w-full">
          <DefaultLandingComponent />
        </div>
      )}
      {showNoResultsFound && (
        <div className="flex flex-column justify-content-center align-items-center w-full h-full" data-testid="no-datasets-search-results">
          <div className="font-semibold text-2xl mb-4">{ t('labels.result', { context: 'notFound', ns: 'impact-dataset' }) }</div>
          <FontAwesomeIcon icon={faSearch} className="text-primary-100" fontSize={96} />
        </div>
      )}
    </>
  )

  return (
    <>
      { impactMethod?.id && (
        <ImpactDatasetContainer type={productReferenceType} impactMethodId={impactMethod.id}>
          <div className="flex bg-white h-full overflow-hidden">

            <SidebarWrapper className="flex flex-column p-0 py-5 h-full">
              <SidebarFilterLayout />
            </SidebarWrapper>

            <ContentWrapper showSearchTips={showSearchTips} className="relative flex flex-column p-4 h-full mt-1">

              <div className="flex w-full border-bottom-1 border-gray-100">
                <SearchFieldComponent />
              </div>

              { getDisplayContent() }

              <div className="flex w-full py-3">
                { showPagination
                    && <PagePaginatorComponent pageLinkSize={5} first={firstProduct} pageSize={PAGINATION.PAGE_SIZE} totalItems={totalItems} onPageChange={onCustomPageChange} /> }
              </div>

              { !showNoResultsFound && !showDefaultLanding && <CompareDatasetsComponent /> }

            </ContentWrapper>

            { showSearchTips && (
              <SidebarWrapper data-testid="search-tips-wrapper" showSearchTips={showSearchTips} className="flex flex-column p-0 pt-5 h-full ml-3">
                <SearchFieldHelpComponent />
              </SidebarWrapper>
            ) }
          </div>
        </ImpactDatasetContainer>
      )}
    </>
  )
}
