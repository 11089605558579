import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import LifecycleContext from '../provider/context/lifecycle.context'
import { TLifecycleContext } from '../interface/lifecycle-context.type'
import { LifecycleOverviewLayout } from '../layout/lifecycle-overview.layout'
import { useLifeCycleOverview } from '../../shared/hook/query/use-lifeCycle-overview'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { CreateLifeCycleContainer } from '../../shared/container/create-lifecycle.container'
import DetailsPanel from '../../../component/model/details-panel/detailsPanel.container'
import LifecycleDetailPanelWrapper from '../../../component/model/lifecycle/lifecycle-detail-panel-wrapper/lifecycleDetailPanelWrapper.container'
import { updateLifecyclesItemsSaga } from '../../../redux/sagas/lifecycle.saga'
import { store } from '../../../configureStore'
import { CopyLifecycleContainer } from './copy-lifecycle.container'
import { DeleteLifecycleContainer } from './delete-lifecycle.container'
import { CreateScenarioContainer } from './create-scenario.container'
import { CreateComparisonContainer } from '../../shared/container/create-comparison.container'
import { ViewType } from '../../shared/enum'
import { ILifecycle, IProduct } from '../../model'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { LifeCycleSortField, SortingOrder } from '../../../__generated__/graphql'
import { TFeatureContext } from '../../dashboard/interface/feature-context.type'
import FeatureContext from '../../dashboard/context/feature.context'
import { Feature } from '../../shared/enum/feature'
import { AnalysisType } from '../../analysis/enum/analysis'
import { useLifecycleAnalysisNavigate } from '../../analysis/hook/use-lifecycle-analysis-navigate'
import { useLifecycleNavigate } from '../hook/use-lifecycle-navigate'

export const LifecycleOverviewContainer = () => {
  const [ trackEventInSegment ] = getSegmentTrack()
  const navigate = useNavigate()

  const { showCreateComparisonDialog = false, showCreateLifeCycleDialog = false } = useContext<IDashboardContext>(DashboardContext)
  const {
    updateLifecycle,
    currentPage = 1,
    cardPageSize,
    tablePageSize,
    selectedLifecycle = {},
    selectedViewType,
    selectedSortBy = LifeCycleSortField.Name,
    selectedSortOrder = SortingOrder.Asc,
    showCreateScenarioDialog = false,
  } = useContext<TLifecycleContext>(LifecycleContext)

  const { isFeatureEnabled } = useContext<TFeatureContext>(FeatureContext)
  const enableLegacyLifecycleInventory = isFeatureEnabled(Feature.LegacyLifecycleInventory) || false

  const {
    lifecycleNavigate,
    accountId,
    spaceId,
    workspaceSlug
  } = useLifecycleNavigate()
  const {
    lifecycleAnalysisNavigate
  } = useLifecycleAnalysisNavigate()
  const isCardView = selectedViewType === ViewType.Grid
  const queryParam = {
    spaceID: spaceId,
    currentPage,
    sortBy: { field: selectedSortBy, order: selectedSortOrder },
    pageSize: isCardView ? cardPageSize : tablePageSize
  }
  const {
    loading: loadingLifeCycleOverview,
    lifeCycleData,
    refetchLifeCycle = () => {}
  } = useLifeCycleOverview(queryParam)
  const {
    lifecyclesPaginated: {
      items: allLifecycles = [],
      total: totalItems = 0
    } = {}
  } = lifeCycleData || {}

  useEffect(() => {
    if (!loadingLifeCycleOverview && lifeCycleData) {
      updateLifecycle({
        refetchLifeCycle, allLifecycles, totalItems, loadingLifeCycleOverview
      })
    }
    updateLifecycle({ loadingLifeCycleOverview })
  }, [ loadingLifeCycleOverview, lifeCycleData ])

  const openDetailAfterChange = (id: string, trackEventType?: TrackEventType) => {
    trackEventType && trackEventInSegment(trackEventType)
    if (!enableLegacyLifecycleInventory) {
      lifecycleNavigate({ selectedLifecycleId: id })
    } else {
      navigate(`/account/${accountId}/workspace/${workspaceSlug}/lifecycle/${id}/inventory`)
    }
  }

  const afterDeleteLifecycle = () => {
    refetchLifeCycle()
  }

  const afterCreateScenario = () => {
    trackEventInSegment(TrackEventType.CREATED_LIFECYCLE_SCENARIO)
  }

  const afterCreateComparison = (comparisonEntity: IProduct | ILifecycle) => {
    if (!accountId || !workspaceSlug || !selectedLifecycle?.id) return

    trackEventInSegment(TrackEventType.OPENED_COMPARISON)
    lifecycleAnalysisNavigate({
      selectedLifecycleId: selectedLifecycle?.id,
      selectedSecondaryEntityId: comparisonEntity.id,
      backLabel: selectedLifecycle?.name
    })
  }

  const reloadLifecycleList = () => {
    refetchLifeCycle()
    store.dispatch(updateLifecyclesItemsSaga())
  }

  return (
    <>
      <LifecycleOverviewLayout />

      { showCreateLifeCycleDialog && <CreateLifeCycleContainer afterCreateLifeCycle={openDetailAfterChange} /> }

      <CopyLifecycleContainer afterCopyLifecycle={openDetailAfterChange} />

      <DeleteLifecycleContainer afterDeleteLifecycle={afterDeleteLifecycle} />

      <CreateScenarioContainer
        selectedLifecycle={selectedLifecycle}
        updateLifecycle={updateLifecycle}
        showCreateScenarioDialog={showCreateScenarioDialog}
        afterCreateScenario={afterCreateScenario}
      />

      { showCreateComparisonDialog && <CreateComparisonContainer afterCreateComparison={afterCreateComparison} analysisType={AnalysisType.LifeCycleToLifeCycle} /> }

      {/* // TODO : From v0.9 : To be refactored */}
      <DetailsPanel>
        <LifecycleDetailPanelWrapper updatePageItems={reloadLifecycleList} />
      </DetailsPanel>
    </>
  )
}
