import { Module } from '../../../__generated__/graphql'
import { LocalStorage } from '../enum/storage'

export const getModuleStorageItems = () => {
  const modules = localStorage.getItem(LocalStorage.Modules)
  if (modules) {
    return JSON.parse(modules)
  }
}

export const setModuleStorageItem = (modules: Module[]) => {
  localStorage.setItem(LocalStorage.Modules, JSON.stringify(modules))
}
