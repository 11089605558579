import { Tooltip } from 'primereact/tooltip'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DecimalPointComponent } from '../../../shared/component/general/decimal-point.component'
import { DecimalViewType } from '../../../shared/enum'
import { ImpactType } from '../../../shared/enum/impact'
import { TooltipOverflowContent, useRefOverflow } from '../../../shared/hook/use-ref-overflow'
import { TDonutItem } from '../../interface/donut-chart.type'

type TDonutLegendItemProps = {
  donutItem: TDonutItem,
  lifecycleUnit?: string
}

export const DonutLegendItemComponent = ({
  donutItem,
  lifecycleUnit
}:TDonutLegendItemProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const [ nameRef, hasNameOverflow, nameDom ] = useRefOverflow()
  const { data: { amount, product } = {} } = donutItem
  const { unit } = product || {}

  const getTooltipContent = () => (
    <TooltipOverflowContent>
      {' '}
      { donutItem.name }
      {' '}
    </TooltipOverflowContent>
  )
  return (
    <>
      <Tooltip target={nameDom} onBeforeShow={() => hasNameOverflow} position="bottom">{ getTooltipContent() }</Tooltip>
      { amount && unit && (
        <div className="flex white-space-nowrap text-base text-grey-500">
          <DecimalPointComponent
            value={amount}
            enableTooltip
            decimalViewType={DecimalViewType.NumericValue}
          />
          { t('labels.treeItemUnit', { context: 'value', unit }) }
        </div>
      )}
      <div ref={nameRef} className="flex-grow-1 text-base text-grey-500 white-space-nowrap overflow-hidden text-overflow-ellipsis pr-2">
        { donutItem.name }
      </div>
      <div className="flex white-space-nowrap text-base text-grey-500">
        { donutItem.impactType === ImpactType.Negative && <>-</> }
        <DecimalPointComponent
          value={donutItem.impactAmount}
          enableTooltip
          decimalViewType={DecimalViewType.NumericValue}
        />
        { lifecycleUnit }
      </div>
    </>
  )
}
