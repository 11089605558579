import React, { useContext, useEffect, useRef } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'

import { CHANGE_PRODUCT_CUSTOM_IMPACTS } from '../../../graphql/mutation'
import { selectedWorkspaceVar } from '../../../../graphql/cache'
import { displayGraphqlErrors } from '../../../shared/util/error'
import { DeleteCustomImpactComponent } from '../../component/dialog/delete-custom-impact.component'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { IProduct } from '../../../model'

type DeleteCustomImpactContainerProps = {
  afterDeleteTreeItem: () => void
}

export const DeleteCustomImpactContainer = ({
  afterDeleteTreeItem
}: DeleteCustomImpactContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const toast = useRef<Toast>(null)
  const {
    selectedEntity,
    showDeleteCustomImpact = false,
    updateDashboard
  } = useContext(DashboardContext)
  const selectedSpace = useReactiveVar(selectedWorkspaceVar) || {}

  const selectedProduct = selectedEntity as IProduct
  const resetedImpacts = (selectedProduct?.customImpacts || []).map((impact: any) => ({
    amount: '0',
    categoryID: impact.impactCategoryID as string,
  }))
  const [
    changeProductCustomImpact,
    {
      error: failedDeletingCustomImpact,
      data: changeProductCusomImpactData,
      loading: removingCustomImpact
    }
  ] = useMutation(CHANGE_PRODUCT_CUSTOM_IMPACTS)
  const deletedCustomImpact = changeProductCusomImpactData?.changeProductCustomImpacts

  useEffect(() => {
    if (!removingCustomImpact && (!!deletedCustomImpact || failedDeletingCustomImpact)) {
      removeCustomImpactCallback()
    }
  }, [ removingCustomImpact, !!deletedCustomImpact, failedDeletingCustomImpact ])

  const closeDeleteDialog = () => {
    updateDashboard({ showDeleteCustomImpact: false })
  }

  const handleDeleteCustomImpact = () => {
    const { space } = selectedSpace
    if (selectedProduct?.id && space?.impactMethod?.id) {
      changeProductCustomImpact({
        variables: {
          productID: selectedProduct.id,
          impactMethodID: space?.impactMethod?.id,
          impacts: resetedImpacts,
          excludeLT: selectedSpace.space?.excludeLT as boolean,
        }
      })
    }
  }

  const removeCustomImpactCallback = () => {
    try {
      if (failedDeletingCustomImpact) {
        throw failedDeletingCustomImpact
      } else if (deletedCustomImpact) {
        afterDeleteTreeItem()
        closeDeleteDialog()
        updateDashboard({ selectedEntity: { ...selectedEntity, customImpacts: resetedImpacts } })
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.success', { context: 'removeCustomImpact', ns: 'product' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.errorSummary', { context: 'removeCustomImpact', ns: 'product' }), error?.graphQLErrors)
    }
  }

  return (
    <>
      <DeleteCustomImpactComponent
        removingInProgress={removingCustomImpact}
        handleDelete={handleDeleteCustomImpact}
        closeDialog={closeDeleteDialog}
        showDialog={showDeleteCustomImpact}
      />

      <Toast data-testid="delete-product-status" ref={toast} position="top-right" />
    </>
  )
}
