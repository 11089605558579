import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TabMenu } from 'primereact/tabmenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faCircleNodes, faPenField } from '@fortawesome/pro-regular-svg-icons'

import { ImpactDatasetViewType } from '../enum/impact-dataset-view-type'

import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { useDatasetNavigate } from '../hook/use-dataset-navigate'

const TabsWrapper = styled.div`
  .p-tabmenu .p-tabmenu-nav {
    border: none;
  }

  .p-tabmenuitem {
    background-color: rgba(255,255,255,0.5);
    border-radius: 4px 4px 0 0;
    height: 2.14rem !important;

    &:nth-child(3) {
      margin-right: 0;
    }

    &.p-highlight {
      background-color: var(--surface-a);
      height: 2rem !important;

      .p-menuitem-link {
        color: var(--primary-500) !important;
      }

      .p-menuitem-text {
        font-weight: 400;
      }
    }
  }

  .p-menuitem-link {
    border: none !important;
    padding: 0.7rem 1rem !important;
    font-size: 14px !important;
    color: #797a7b !important;

    .p-menuitem-text {
      margin-left: 0.25rem;
    }

    &:hover {
      color: var(--primary-500) !important;
    }
  }
`

export const ImpactDatasetTabsComponent = () => {
  const { t } = useTranslation([ 'impact-dataset' ])

  const {
    selectedDatasetViewType,
    updateImpactDataset
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)

  const { datasetNavigate, activeTab } = useDatasetNavigate()

  useEffect(() => {
    activeTab
      ? updateImpactDataset({ selectedDatasetViewType: activeTab as ImpactDatasetViewType, makeInitDatasetsCall: true })
      : updateImpactDataset({ selectedDatasetViewType: ImpactDatasetViewType.DatabaseSearch, makeInitDatasetsCall: true })
  }, [ activeTab ])

  const items = [
    { label: t('labels.databaseSearch'), icon: <FontAwesomeIcon icon={faDatabase} />, viewType: ImpactDatasetViewType.DatabaseSearch },
    { label: t('labels.elementaryFlow'), icon: <FontAwesomeIcon icon={faCircleNodes} />, viewType: ImpactDatasetViewType.ElementaryFlow },
    { label: t('labels.customImpact'), icon: <FontAwesomeIcon icon={faPenField} />, viewType: ImpactDatasetViewType.CustomImpact },
  ]

  const getActiveIndex = (viewType?: ImpactDatasetViewType) => {
    const result = Object.values(ImpactDatasetViewType).findIndex(tab => tab === viewType) || 0
    return result
  }

  const handleTabChange = (event: any) => {
    const { value: { viewType } } = event || {}
    if (selectedDatasetViewType !== viewType) {
      datasetNavigate(viewType)
    }
  }

  return (
    <TabsWrapper className="flex align-items-center">
      <div className="flex flex-grow-1">
        <TabMenu
          model={items}
          activeIndex={getActiveIndex(selectedDatasetViewType)}
          onTabChange={(event: any) => handleTabChange(event)}
        />
      </div>
    </TabsWrapper>
  )
}
