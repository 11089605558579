import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/pro-regular-svg-icons'

import { AddImpactDatasetContainer } from '../container/add-impact-dataset.container'
import { IReferenceProduct } from '../../model'
import {
  formatTimePeriodDescription,
  formatGeographyDescription,
} from '../util/content'
import { FormattedContentComponent } from './formatted-content.component'

type TDetailContentComponentProps = {
  selectedReferenceProduct: IReferenceProduct,
  isDatabaseSearchViewType: boolean,
  isSidebar?: boolean
}
export const DetailContentComponent = ({
  selectedReferenceProduct,
  isDatabaseSearchViewType,
  isSidebar = false
}: TDetailContentComponentProps) => {
  const { t } = useTranslation([ 'impact-dataset', 'common' ])
  const {
    name,
    referenceProduct,
    databaseName,
    referenceUnit,
    referenceProperty,
    location,
    category,
    parentCategory,
    description,
    technology,
    timeDescription,
    timeFrom,
    timeUntil,
    geography,
    databaseCategoryPath,
  } = selectedReferenceProduct

  const isEcoinventDatabase = databaseName?.toLowerCase()?.includes('ecoinvent')
  const formattedTime = formatTimePeriodDescription(timeFrom, timeUntil, timeDescription)
  const formattedGeography = formatGeographyDescription(location?.name, geography)
  const showDatasetDescriptionView = description || technology || formattedTime || geography || databaseCategoryPath

  const getSection = (header: string, content?: string, dataCy?: string) => (
    <>
      { content && (
        <div className="flex flex-column w-full gap-2">
          <div className="block w-full text-sm font-medium">{header}</div>
          <div className="block w-full text-sm text-wrap" data-cy={dataCy}>{content}</div>
        </div>
      )}
    </>
  )

  return (
    <div className="flex flex-column w-full h-full overflow-y-auto gap-5">

      { isSidebar
        ? (
          <>
            <div className="flex w-full">
              { selectedReferenceProduct.id && <AddImpactDatasetContainer referenceProductID={selectedReferenceProduct?.id} isFromDatasetDetail /> }
            </div>

            { getSection(t('labels.geography'), location?.name) }

            { getSection(t('labels.referenceProduct'), referenceProduct) }

            { getSection(t('labels.datasetName'), name) }

            { getSection(t('labels.unit', { ns: 'common' }), referenceUnit?.name) }

            { getSection(t('labels.referenceProperty'), referenceProperty?.name) }

            { isDatabaseSearchViewType && getSection(t('labels.database'), databaseName) }

            { isDatabaseSearchViewType && getSection(t('labels.databaseCategory'), category?.name) }

            { !isDatabaseSearchViewType && getSection(t('labels.compartment'), parentCategory?.name) }

            { !isDatabaseSearchViewType && getSection(t('labels.compartment', { context: 'sub' }), category?.name) }
          </>
        )
        : (
          <>
            { description && <FormattedContentComponent dataCy="dataset-description" content={description} header={t('labels.description', { ns: 'common' })} isEcoinventDatabase={isEcoinventDatabase} /> }

            <FormattedContentComponent dataCy="dataset-technology" content={technology} header={t('labels.technology')} isEcoinventDatabase={isEcoinventDatabase} />

            { formattedTime && getSection(t('labels.time'), formattedTime, 'dataset-time-section') }

            { showDatasetDescriptionView && formattedGeography && getSection(t('labels.geography'), formattedGeography, 'dataset-geography-description') }

            <FormattedContentComponent dataCy="dataset-database-category-path" content={databaseCategoryPath} header={t('labels.databaseCategoriesPath')} isCategoryPath />

            { !showDatasetDescriptionView && (
              <div className="flex flex-column justify-content-center align-items-center w-full h-full" data-testid="no-datasets-search-results">
                <div className="font-semibold text-2xl w-20rem mb-4">{ t('labels.noDescription', { context: !isDatabaseSearchViewType ? 'elementaryFlow' : 'databaseSearch' }) }</div>
                <FontAwesomeIcon icon={faBook} className="text-primary-100" fontSize={96} />
              </div>
            )}
          </>
        )}

    </div>
  )
}
