import React, {
  useContext, useState, useRef, useEffect
} from 'react';
import styled from 'styled-components'
import { classNames } from 'primereact/utils'
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client'
import { Tooltip } from 'primereact/tooltip'
import { Button } from 'primereact/button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar, faDatabase, faCircleNodes, faPen, faTrash, faPenField
} from '@fortawesome/pro-regular-svg-icons';

import { IDashboardContext } from '../../../../shared/interface/workspace-context-type';
import DashboardContext from '../../../../dashboard/context/dashboard.context';
import { IProduct } from '../../../../model';
import { ReferenceProductType } from '../../../../impact-dataset/enum/reference-product';
import AnalysisContext from '../../../../analysis/provider/context/analysis.context';
import { useDatasetNavigate } from '../../../../impact-dataset/hook/use-dataset-navigate';
import { DecimalPointComponent } from '../../../../shared/component/general/decimal-point.component';
import { DecimalViewType } from '../../../../shared/enum';
import { selectedWorkspaceVar } from '../../../../../graphql/cache';
import { TooltipOverflowContent } from '../../../../shared/hook/use-ref-overflow';
import { ImpactDatasetViewType } from '../../../../impact-dataset/enum/impact-dataset-view-type';

const ProductName = styled.div`
  min-height: 34px;
  max-height: 54px;
  overflow: hidden;
  line-height: 1.125rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: normal;
  -webkit-line-clamp: 'none';
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`

export const ImpactPreviewComponent = () => {
  const { t } = useTranslation([ 'impact-dataset' ]);
  const [ isOverflowing, setIsOverflowing ] = useState(false)
  const [ enableChangeDataset, setEnableChangeDataset ] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)
  const unitRef = useRef<HTMLDivElement>(null)
  const datasetRef = useRef<HTMLDivElement>(null)
  const {
    selectedEntity,
    selectedEntityImpact,
    updateDashboard,
    closeSidebar = () => {}
  } = useContext<IDashboardContext>(DashboardContext);
  const selectedProduct = selectedEntity as IProduct
  const { scenarioProductID, scenarioSelectedKey } = useContext(AnalysisContext)
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { datasetNavigate, navigateToDatasetDetail } = useDatasetNavigate(selectedProduct, scenarioProductID, scenarioSelectedKey)
  const { referenceProduct, hasInventory, customImpacts } = selectedProduct || {}
  const {
    impactCategory, impactMethod, excludeLT
  } = space || {}

  const productHasCustomImpact = customImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)
  const productHasNoImpact = !productHasCustomImpact && !referenceProduct
  const isCustomImpact = !referenceProduct && productHasCustomImpact

  useEffect(() => {
    if (!textRef.current) return

    const element = textRef.current
    if (element.scrollHeight > element.clientHeight) {
      element.style.webkitLineClamp = '3'
      setIsOverflowing(true)
    } else {
      setIsOverflowing(false)
    }
  }, [ selectedProduct?.name ])

  const handleChangeReference = (event: any) => {
    event.stopPropagation();

    if (hasInventory) return;

    datasetNavigate();
    closeSidebar();
  }

  const handleRemoveReference = (event: any) => {
    event.stopPropagation();
    const removeDetails = isCustomImpact ? { showDeleteCustomImpact: true } : { showDeleteReferenceProduct: true }
    updateDashboard(removeDetails)
  }

  const openImpactDetail = (event: any) => {
    event.stopPropagation()
    if (!referenceProduct || isCustomImpact) return

    closeSidebar();
    const selectedDatasetViewType = referenceProduct?.type === ReferenceProductType.ProductSystem
      ? ImpactDatasetViewType.DatabaseSearch
      : ImpactDatasetViewType.ElementaryFlow
    navigateToDatasetDetail({
      viewType: selectedDatasetViewType,
      selectedReferenceProduct: referenceProduct
    })
  }

  const getProductNameTooltip = () => (
    <TooltipOverflowContent>
      <strong>{ t('labels.header', { context: 'impactPreview', ns: 'impact-dataset', impactCategory: impactCategory?.name?.toLowerCase() }) } </strong>
      { selectedProduct?.name }
    </TooltipOverflowContent>
  )

  const getUnitTooltip = () => (
    <TooltipOverflowContent>
      <div className="flex flex-column">
        <div className="flex justify-content-start align-items-center ">{ impactMethod?.name } </div>
        <div className="flex justify-content-start align-items-center ">{ impactCategory?.name } </div>
        <div className="flex justify-content-start align-items-center ">{ t('labels.exclude', { context: 'lt', value: excludeLT ? 'Yes' : 'No' }) } </div>
        { selectedEntityImpact?.amount && (
          <div className="flex justify-content-start font-medium">
            <DecimalPointComponent value={selectedEntityImpact.amount} enableTooltip decimalViewType={DecimalViewType.NumericValue} />
            <div className="flex align-items-center">{ selectedEntityImpact?.unit }</div>
          </div>
        )}
      </div>
    </TooltipOverflowContent>
  )

  const getCategoryTooltip = (label: string) => (
    <div className="flex flex-column w-25rem">
      <div className="flex justify-content-start align-items-center ">
        { impactMethod?.name }
      </div>
      <div className="flex justify-content-start align-items-center ">{ impactCategory?.name } </div>
      <div className="flex justify-content-start">{label}</div>
    </div>
  )

  const datasetPreviewTemplate = (icon: any, label: string, isProductSystem: boolean = false) => (
    <div className="flex w-full gap-2" onMouseEnter={() => setEnableChangeDataset(!hasInventory)} onMouseLeave={() => setEnableChangeDataset(false)}>
      { datasetRef.current && <Tooltip target={datasetRef.current} position="left">{getCategoryTooltip(label)}</Tooltip> }
      <div
        className={classNames('flex align-items-start text-primary-500', {
          'pt-1': isProductSystem
        })}
      >
        <FontAwesomeIcon icon={icon} className="mr-1" />
      </div>
      <div
        ref={datasetRef}
        onClick={openImpactDetail}
        className={classNames('flex align-items-center text-sm line-height-3 flex-grow-1', {
          'hover:text-primary-500 cursor-pointer': !hasInventory
        })}
      >
        { label }
      </div>
      { !hasInventory && (
        <div className="flex flex-none text-base gap-2 w-2rem">
          { enableChangeDataset && (
            <>
              <FontAwesomeIcon icon={faPen} className="text-gray-300 hover:text-primary-500 cursor-pointer" onClick={handleChangeReference} />
              <FontAwesomeIcon icon={faTrash} className="text-red-300 hover:text-primary-500 cursor-pointer" onClick={handleRemoveReference} />
            </>
          )}
        </div>
      )}
    </div>
  )

  const renderImpactPreview = () => {
    if (hasInventory || !selectedProduct) return

    return (
      <div className="flex w-full">
        <div className="flex justify-content-start flex-grow-1 text-sm">
          { t('labels.impactPreview', { context: 'forOneUnit', unit: selectedProduct?.unit || '-' }) }
        </div>
        <div className="flex flex-grow-1 text-sm justify-content-end">
          { !productHasNoImpact && selectedEntityImpact?.amount ? (
            <>
              { unitRef.current && <Tooltip target={unitRef.current} position="left">{getUnitTooltip()}</Tooltip> }
              <div ref={unitRef} className="flex align-items-center justify-content-end">
                <DecimalPointComponent value={selectedEntityImpact.amount} decimalViewType={DecimalViewType.NumericValue} />
                <div className="flex align-items-center justify-content-center">{ selectedEntityImpact?.unit }</div>
              </div>
            </>
          ) : (
            <>-</>
          )}
        </div>
      </div>
    )
  }

  const renderDatasetPreview = () => {
    if (hasInventory) {
      return datasetPreviewTemplate(faChartBar, t('labels.impactPreview', { context: 'calculatedFromSubItem' }));
    }

    if (isCustomImpact) {
      return datasetPreviewTemplate(faPenField, t('labels.customImpact'));
    }

    const { name, type } = referenceProduct || {};

    if (name && type) {
      const icon = type === ReferenceProductType.ProductSystem ? faDatabase : faCircleNodes;
      const showTooltip = type === ReferenceProductType.ProductSystem;

      return datasetPreviewTemplate(icon, name, showTooltip);
    }

    return null;
  };

  if (!hasInventory && productHasNoImpact) {
    return (
      <div className="flex flex-column gap-3 w-full">
        <div className="flex w-full px-4">
          <div className="flex justify-content-start flex-grow-1 text-sm text-base font-medium">
            { t('labels.impact_forValue', { value: '-' }) }
          </div>
          <div className="flex justify-content-end flex-grow-1 text-sm">
            -
          </div>
        </div>
        <div className="flex align-items-start flex-column w-full px-4">
          <Button
            label="Add impact"
            onClick={handleChangeReference}
            className="p-button-primary h-2rem text-sm font-normal"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-column gap-4 w-full">
      { textRef.current && <Tooltip target={textRef.current} onBeforeShow={() => isOverflowing} position="left">{getProductNameTooltip()}</Tooltip> }
      <div className="flex align-items-start flex-column w-full px-4">
        <div className="flex align-items-center justify-content-start text-base">
          { t('labels.header', { context: 'impactPreview', ns: 'impact-dataset', impactCategory: impactCategory?.name?.toLowerCase() }) }
        </div>
        {selectedProduct?.name && (
          <ProductName ref={textRef} className="text-base font-medium">
            { selectedProduct.name }
          </ProductName>
        )}
      </div>

      <div className="flex align-items-start flex-column w-full px-4 gap-3">
        { renderImpactPreview() }
        { renderDatasetPreview() }
      </div>
    </div>
  );
}
