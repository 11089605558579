import React, { useState } from 'react'
import { Panel } from 'primereact/panel'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TDonutPhase } from '../../interface/donut-chart.type'

import { DonutLegendItemComponent } from './donut-legend-item.component'

import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { DecimalViewType } from '../../../shared/enum'
import { DecimalPointComponent } from '../../../shared/component/general/decimal-point.component'
import { ImpactType } from '../../../shared/enum/impact'
import { TAnalysisContext } from '../../../analysis/interface/analysis.context'
import AnalysisContext from '../../../analysis/provider/context/analysis.context'

const DonutLegendPhaseComponentWrapper = styled.div`
  .p-panel {
    width: 100%;

    .p-panel-content {
      padding: 0 0.25rem 0 2.25rem;
    }
  }
`

type TDonutLegendComponentProps = {
  donutPhase: TDonutPhase,
  index: number,
  background: string
}

export const DonutLegendPhaseComponent = ({ donutPhase, background, index } : TDonutLegendComponentProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const [ panelCollapsed, setPanelCollapsed ] = useState<boolean>(index !== 0)
  const { lifecycleTotalImpact, analysisMode } = React.useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const { primaryTotalImpact } = React.useContext<TAnalysisContext>(AnalysisContext)
  const legendItems = donutPhase.children || []
  const lifecycleUnit = analysisMode ? primaryTotalImpact?.unit : lifecycleTotalImpact?.unit

  const negativeChartItems = legendItems.filter(item => item?.impactType && item.impactType === ImpactType.Negative)

  const negativeImpactSummary = negativeChartItems.reduce((acc, item) => {
    const impactAmount = item?.impactAmount || 0
    return acc + impactAmount
  }, 0)

  const headerTemplate = () => (
    <div className="flex flex-none w-full">
      <div
        className="flex align-items-center justify-content-center w-2rem h-2rem cursor-pointer"
        onClick={() => setPanelCollapsed(!panelCollapsed)}
      >
        <FontAwesomeIcon className="text-gray-300 w-2rem" icon={faAngleRight} rotation={!panelCollapsed ? 90 : undefined} />
      </div>
      { background && (
        <div className="flex w-1rem align-content-center align-items-center mr-2">
          <div className="w-1rem h-1rem border-circle border-3 border-white" style={{ background }}></div>
        </div>
      ) }
      <div className="flex-grow-1 p-1 text-base font-medium">{ donutPhase.name }</div>
      <div className="flex p-1 white-space-nowrap text-base font-medium">
        { donutPhase.totalImpactType === ImpactType.Negative && <>-</> }
        <DecimalPointComponent
          value={donutPhase.totalImpactAmount}
          enableTooltip
          decimalViewType={DecimalViewType.NumericValue}
        />
        { lifecycleUnit }
      </div>
    </div>
  )

  return (
    <DonutLegendPhaseComponentWrapper className="flex w-full mt-1">
      <Panel collapsed={panelCollapsed} headerTemplate={headerTemplate} toggleable onToggle={(e: any) => setPanelCollapsed(e.value)}>
        <div className="flex flex-column w-full">
          {
            legendItems.map((donutItem, itemIndex) => (
              <div key={itemIndex} className="flex w-full my-2 gap-1">
                <DonutLegendItemComponent donutItem={donutItem} lifecycleUnit={lifecycleUnit} />
              </div>
            ))
          }
          { negativeChartItems.length > 0
          && (
            <div data-testid="doughnut-legend-negative" className="flex w-full my-2 gap-1">
              <DonutLegendItemComponent
                donutItem={{
                  name: t('labels.impactBelowZero'),
                  impactAmount: negativeImpactSummary,
                  impactType: ImpactType.Negative
                }}
                lifecycleUnit={lifecycleUnit}
              />
            </div>
          )}
        </div>
      </Panel>
    </DonutLegendPhaseComponentWrapper>
  )
}
