import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { TreeFooterComponent } from './tree-footer.component'
import { TreeHeaderComponent } from './tree-header.component'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import { InventoryViewType } from '../../enum/inventory-view-type'
import FeatureContext from '../../../dashboard/context/feature.context'
import { Feature } from '../../../shared/enum/feature'

type TFixedSectionProps = {
  collapsedNavigation: boolean,
  openedSidebar: boolean
}

// TODO : Upon cleaning up TreeDnDPolarChartFixedSidebar remember to revert the comment sections for width and right
const TreeFixedSection = styled.div<TFixedSectionProps>`
  // width: ${(props: TFixedSectionProps) => (props.collapsedNavigation ? 'calc(100% - 5rem)' : 'calc(100% - 15.5rem)')};
  width: ${(props: TFixedSectionProps) => {
    let deduct = 0;

    deduct += props.openedSidebar ? 20.45 : 0
    deduct += props.collapsedNavigation ? 5 : 15

    return `calc(100% - ${deduct}rem)`
  }};
  // right: 0.5rem;
  right: ${(props: TFixedSectionProps) => (props.openedSidebar ? '21.875rem)' : '0rem')};
`

export const TreeFixedSectionComponent = () => {
  // TODO : Upon cleaning up TreeDnDPolarChartFixedSidebar remember to remove openedSidebar variable
  const { openedSidebar = false, collapsedNavigation = false } = useContext<IDashboardContext>(DashboardContext)
  const { isFeatureEnabled } = useContext(FeatureContext)
  const enableTreeDnDPolarChartFixedSidebar = isFeatureEnabled(Feature.TreeDnDPolarChartFixedSidebar) || false
  const {
    selectedInventoryViewType = InventoryViewType.Model
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const [ showFixedHeader, setShowFixedHeader ] = useState<boolean>(false)
  const [ showFixedFooter, setShowFixedFooter ] = useState<boolean>(false)
  useEffect(() => {
    const modalBodyDOM = document.querySelector('.product-detail-wrapper')

    const updateHeaderFooterStyle = () => {
      if (modalBodyDOM && modalBodyDOM.scrollHeight > modalBodyDOM.clientHeight) {
        isModelViewType() && setShowFixedFooter(true)
      } else {
        isModelViewType() && setShowFixedFooter(false)
      }

      if (modalBodyDOM && modalBodyDOM.scrollTop > 210) {
        isModelViewType() && setShowFixedHeader(true)
      } else {
        isModelViewType() && setShowFixedHeader(false)
      }
    }
    updateHeaderFooterStyle()

    if (modalBodyDOM) {
      modalBodyDOM.addEventListener('scroll', updateHeaderFooterStyle)
      window.addEventListener('resize', updateHeaderFooterStyle)
    }

    return () => {
      setShowFixedFooter(false)
      setShowFixedHeader(false)
      window.removeEventListener('scroll', updateHeaderFooterStyle)
      window.removeEventListener('resize', updateHeaderFooterStyle)
    }
  })

  const isModelViewType = () => selectedInventoryViewType === InventoryViewType.Model

  return (
    <>
      { isModelViewType() && showFixedHeader && (
        <TreeFixedSection openedSidebar={openedSidebar && enableTreeDnDPolarChartFixedSidebar} data-testid="header-fixed-section" className="fixed top-0 h-3rem px-6" collapsedNavigation={collapsedNavigation} data-html2canvas-ignore>
          <div className="flex w-full h-full bg-white border-bottom-1 border-gray-100 px-5">
            <TreeHeaderComponent />
          </div>
        </TreeFixedSection>
      ) }
      { isModelViewType() && showFixedFooter && (
        <TreeFixedSection openedSidebar={openedSidebar && enableTreeDnDPolarChartFixedSidebar} data-testid="footer-fixed-section" className="fixed bottom-0 h-4rem px-6" collapsedNavigation={collapsedNavigation} data-html2canvas-ignore>
          <div className="flex w-full h-full py-3 px-5 bg-white border-top-1 border-gray-100">
            <TreeFooterComponent classNamePrefix="ap-tree-footer-fixed" />
          </div>
        </TreeFixedSection>
      ) }
    </>
  )
}
